import {
  SET_CURRENT_DIVER_FOCUS,
  SET_DIVER_FOCUSED,
  SET_UPDATED_STATUSES,
  CLEAR_LEADERSHIP,
  CURRENT_ROUND_SCREEN_SLIDED,
  SET_COACH_OPTIONS,
} from "store/actionTypes";

export const setCurrentDiverFocus = (payload) => {
  return {
    type: SET_CURRENT_DIVER_FOCUS,
    payload,
  };
};

export const setDiverFocusedAction = (payload) => {
  return {
    type: SET_DIVER_FOCUSED,
    payload,
  };
};

export const setUpdatedStatusesAction = (payload) => {
  return {
    type: SET_UPDATED_STATUSES,
    payload,
  };
};

export const clearLeadershipInfo = () => {
  return {
    type: CLEAR_LEADERSHIP,
  };
};

export const setCurrentRoundScreenSlidedAction = (payload) => {
  return {
    type: CURRENT_ROUND_SCREEN_SLIDED,
    payload,
  };
};

export const setCoachOptions = (payload) => ({
  type: SET_COACH_OPTIONS,
  payload,
});
