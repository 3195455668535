import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { ROUTE } from "constants/routing";
import { LEADERSHIP_MODES } from "constants/leadershipModes";
import { IS_IOS, TOP_PADDING } from "constants/settings";

import { customBreakpoints } from "assets/styles/customBreakpoints";
import { userIdSelector } from "store/selectors/user";

import TeamSelect from "Components/TeamSelect";
import ColorModeToggle from "Components/ColorModeToggle";
import BackgroundWaves from "Components/BackgroundWaves";
import JudgeModeRulesButton from "Scenes/JudgeMode/JudgeModeRulesButton";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

const StyledContainer = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.basicBackGroundColor};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${IS_IOS ? 55 : 5}px 20px 5px;
  width: 100%;
  box-sizing: border-box;
  ${({ isJudgeMode }) =>
    isJudgeMode &&
    customBreakpoints.xxs`
    padding: 1vh 20px 5px;
  `}
`;

const StyledTeamPlaceholder = styled.div`
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 3vh;
`;

const MobileWrapper = ({
  containerStyles,
  children,
  withTeamSelector,
  withColorModeToggle,
  isJudgeMode,
}) => {
  const userId = useSelector(userIdSelector);

  const { params = {} } =
    useRouteMatch(
      ROUTE.LEADERSHIP(":eventId", ":screenName", ":extraScreenInfo"),
    ) || {};
  const isDiverView =
    params.screenName === LEADERSHIP_MODES.currentRound &&
    params.extraScreenInfo === LEADERSHIP_MODES.diverView;

  return (
    <StyledWrapper>
      <StyledContainer
        style={{
          maxWidth: isDiverView ? "100%" : "450px",
          paddingTop: withColorModeToggle ? 0 : TOP_PADDING,
          ...containerStyles,
        }}
      >
        <BackgroundWaves isMobile />
        {isJudgeMode || withColorModeToggle ? (
          <HeaderWrapper isJudgeMode={isJudgeMode}>
            {isJudgeMode && <JudgeModeRulesButton />}
            {withColorModeToggle && <ColorModeToggle />}
          </HeaderWrapper>
        ) : null}
        {withTeamSelector && userId && (
          <StyledTeamPlaceholder>
            <TeamSelect />
          </StyledTeamPlaceholder>
        )}
        {children}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default MobileWrapper;
