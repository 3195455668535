import SwitchToPcModalMessage from "Components/SwitchToPcModalMessage";

import { ROUTE } from "./routing";
import { GOVERNING_BODIES } from "./types";

export const MULTIPLYING_JUDGE_AMOUNT = 2;
export const DISAPPEAR_ELEMENT_ANIMATION_LENGTH = 300;
export const PLATFORM_HEIGHT_BY_AGREEMENT = 5;
export const PLATFORM_HEIGHTS = [5, 7.5, 10];
export const MIXED_HEIGHTS = [1, 3, ...PLATFORM_HEIGHTS];
export const NFHS_MIXED_HEIGHTS = [1, 3];
export const MIN_PLATFORM_HEIGHT = Math.min(...PLATFORM_HEIGHTS);
export const DEFAULT_PLATFORM_HEIGHT = Math.max(...PLATFORM_HEIGHTS);
export const MIXED_EVENT_HEIGHT = 0;

export const getMixedHeights = (governingBody) => {
  switch (governingBody) {
    case GOVERNING_BODIES.NFHS:
      return NFHS_MIXED_HEIGHTS;
    default:
      return MIXED_HEIGHTS;
  }
};

export const getMinMixedHeights = (governingBody) => {
  const mixedHeights = getMixedHeights(governingBody);

  return Math.min(...mixedHeights);
};

/* eslint-disable */
export const pass_regexp =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\/!@#$%^&*."'()+,\-:;<=>?[\]^_`{|}~])/; // [!@#$%^&*."'()+,:;<=>?^_`{|}~\[\]\/\\\-]
/* eslint-enable */
export const DEFAULT_NFHS_HEIGHTS = {
  voluntary: 1,
  optional: 5,
};
export const support = {
  phone: "+1 978-999-2415",
  links: {
    faq: "https://scoresandmore.live/faq-3/",
    addYourTeam: "https://scoresandmore.live/add-your-team/",
    infoVideo: "https://scoresandmore.live/dive-live-tutorials/",
    termsAndConditions: "https://scoresandmore.live/?page_id=15",
    privacyPolicy: "https://scoresandmore.live/?page_id=17",
  },
};

export const MEET_JUDGES_TABS = {
  ADD: "add",
  ASSIGN_EVENTS: "assignEvent",
  ASSIGN_JUDGES: "assignJudges",
};

// Note. ID is used as max possible number of invited judges.
export const JUDGE_NUMBERS = {
  2: "2 Judges",
  3: "3 Judges",
  5: "5 Judges",
  7: "7 Judges",
};

export const DEFAULT_JUDGES_SCHEMA_ID = 3;

export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.scoresandmore.android.prod";
export const APP_STORE_LINK =
  "https://apps.apple.com/us/app/dive-live/id1582806053";

export const modalStateForAdministrationClick = (meetId, t, history) => ({
  message: <SwitchToPcModalMessage />,
  leftBtnName: t("CLOSE"),
  rightBtnName: t("DESKTOP"),
  leftBtnColor: ({ theme }) => theme.modalBlueBtnColor,
  rightBtnColor: ({ theme }) => theme.modalBlueBtnColor,
  onLeftBtnClick: (e) => {
    e.stopPropagation();
  },
  onRightBtnClick: (e) => {
    e.stopPropagation();
    history.push(ROUTE.MEETS_EVENTS(meetId));
  },
});

export const GOOGLE_MAPS = {
  GOOGLE_MAP_LINK_BODY: "https://www.google.com/maps/search/?api=1&query=",
  COMMA_UNICODE: "%2C",
};

export const SHARE_HASHTAGS = ["divelive", "scoresandmore"];
export const SHARE_HASHTAGS_STRING = `#${SHARE_HASHTAGS.join("#")}`;

export const SEARCH_LENGTH_MIN = 3;
export const SEARCH_TIMEOUT = 700;

export const IS_IOS =
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform) ||
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);
export const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent,
);

export const TOP_PADDING = `${IS_IOS ? 6 : 4.5}vh`;

export const CLIENT_APP_VERSION = "clientAppVersion";
export const APP_NEW_VERSION = "appNewVersion";
