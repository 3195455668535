import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  BsDot,
  BsPlayFill,
  BsShare,
  BsTrash,
  BsStarFill,
  BsStar,
} from "react-icons/bs";
import { MdOutlineReport } from "react-icons/md";
import { useTranslation } from "react-i18next";

import defaultImagePreview from "assets/images/mocks/defaultVideoPreview.svg";

import { GCP_URL } from "constants/env";

import { getYesNoModalDefaultState } from "constants/types";
import { userDetailsSelector } from "store/selectors/user";
import { shareModalIdSelector } from "store/selectors/video";

import ButtonDropdown from "Components/ButtonDropdown";
import ShareModal from "Components/ShareModal";
import ConfirmationModal from "Components/ConfirmationModal";
import ReportVideoModal from "Components/ReportVideoModal";

import { VIDEO_ACTIONS_MAP } from "./constants";

const ICON_SIZE = 27;

const iconCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size || 28}px;
  height: ${({ size }) => size || 28}px;
  padding: 0;
  font-size: ${ICON_SIZE}px;
  color: ${({ isPlay, theme }) =>
    isPlay ? theme.accentTextColor : theme.basicTextColor};
  background: ${({ isPlay, theme }) =>
    isPlay
      ? theme.basicBackGroundColor
      : `rgba(${theme.basicBgRevertRgb}, 0.5)`};
  border-radius: 50%;
  overflow: hidden;

  > svg {
    flex-shrink: 0;
  }

  :hover {
    background: ${({ theme }) => theme.basicBgOnHover};
  }
`;

const IconButton = styled.button`
  ${iconCss}
  margin: 7px 2px;
  border: none;
  cursor: pointer;
`;

const IconLabel = styled.span`
  ${iconCss}
`;

const ThumbnailImageContainer = styled.div`
  position: relative;
  border-radius: 4px;
`;

const ThumbnailImage = styled.img`
  border-radius: 4px;
  object-fit: cover;
`;

const ThumbnailText = styled.p`
  line-height: 1.2;
  font-weight: 600;
  font-size: 16px;
`;

const navIcons = css`
  position: absolute;
  display: flex;
  align-items: center;
`;

const NavIconsTopRight = styled.div`
  ${navIcons}

  top: 0;
  right: 5px;
  max-width: 100%;
`;

const NavIconsCenter = styled.div`
  ${navIcons}

  top: calc(50% - 20px);
  left: 0;
  justify-content: center;
  width: 100%;
`;

const ThumbnailWrap = styled.div`
  margin: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.basicBackGroundColor};
  border-radius: 4px;
`;

const ThumbnailVideo = ({
  url,
  id,
  height = "auto",
  width = "auto",
  totalAward,
  diveHeight,
  diveCode,
  meetName,
  divers = [],
  onClickPlay,
  title = "",
  text = "",
  onRemove = () => {},
  onLike = () => {},
  actionsConfig = [],
  additionalNavIcons = [],
  featured = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userDetails = useSelector(userDetailsSelector);
  const shareModalId = useSelector(shareModalIdSelector);

  const [modalComponent, setModalComponent] = useState(null);
  const [imagePreview, setImagePreview] = useState(`${GCP_URL}${url}`);

  const description = !!meetName
    ? `${divers
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(
          " and ",
        )} scored ${totalAward} points on ${diveHeight}M ${diveCode} at ${meetName}`
    : text;
  const isVideoCanBeDeleted =
    divers.some(({ id }) => id === userDetails?.id) || userDetails?.isAdmin;
  const isVideoCanBeLiked = divers
    .map(({ id }) => id)
    .includes(userDetails?.id);

  const onErrorImagePreview = () => setImagePreview(defaultImagePreview);

  const actionsMap = {
    [VIDEO_ACTIONS_MAP.STAR]: {
      route: "#",
      text: t("Like"),
      callback: (e) => {
        e.preventDefault();
        onLike({ videoId: id, featured: !featured });
      },
      icon: featured ? (
        <BsStarFill fontSize={12} fill="#F08803" />
      ) : (
        <BsStar fontSize={12} />
      ),
      hidden: !isVideoCanBeLiked,
    },
    [VIDEO_ACTIONS_MAP.SHARE]: {
      route: "#",
      text: t("Share video"),
      callback: async (e) => {
        e.preventDefault();

        const { onClickShareVideo } = await import("helpers/video");

        onClickShareVideo({ id, text: description, dispatch });
      },
      icon: <BsShare fontSize={12} />,
    },
    [VIDEO_ACTIONS_MAP.REPORT]: {
      route: "#",
      text: t("Report video"),
      callback: async (e) => {
        e.preventDefault();

        setModalComponent(
          <ReportVideoModal id={id} onClose={() => setModalComponent(null)} />,
        );
      },
      icon: <MdOutlineReport fontSize={12} />,
    },
    [VIDEO_ACTIONS_MAP.DELETE]: {
      route: "#",
      text: t("Delete video"),
      callback: (e) => {
        e.preventDefault();

        setModalComponent(
          <ConfirmationModal
            {...{
              ...getYesNoModalDefaultState(t),
              message: t("Are you sure you want to delete this video?"),
              onLeftBtnClick: (e) => {
                e.stopPropagation();
                onRemove(id);
              },
              onBackdropClick: () => setModalComponent(null),
            }}
          />,
        );
      },
      icon: <BsTrash fontSize={12} />,
      hidden: !isVideoCanBeDeleted,
    },
  };
  const dropdownItems = actionsConfig.map((key) => ({
    ...actionsMap[key],
    id: key,
  }));

  useEffect(() => {
    setImagePreview(`${GCP_URL}${url}`);
  }, [url]);

  useEffect(() => {
    const isOpen = id ? shareModalId === id : false;

    if (!isOpen) {
      setModalComponent(null);

      return;
    }

    setModalComponent(<ShareModal />);
  }, [id, shareModalId]);

  return url ? (
    <>
      {modalComponent}
      <ThumbnailWrap className="thumbnailWrap">
        <ThumbnailImageContainer>
          <NavIconsTopRight className="thumbnailNavIconsTopRight">
            {additionalNavIcons
              .filter((key) => !actionsMap[key].hidden)
              .map((key) => {
                const { callback, icon } = actionsMap[key];

                return (
                  <IconButton size={20} key={key} onClick={callback}>
                    {icon}
                  </IconButton>
                );
              })}
            <ButtonDropdown
              items={dropdownItems}
              buttonIcon={
                <IconLabel>
                  <BsDot fontSize={14} />
                  <BsDot fontSize={14} style={{ margin: -9 }} />
                  <BsDot fontSize={14} />
                </IconLabel>
              }
              buttonStyleType="link"
              buttonStyle={{ textDecoration: "none" }}
            />
          </NavIconsTopRight>
          <NavIconsCenter>
            <IconButton onClick={onClickPlay} isPlay>
              <BsPlayFill size={24} style={{ marginLeft: 3 }} />
            </IconButton>
          </NavIconsCenter>

          <ThumbnailImage
            src={imagePreview}
            height={height}
            width={width}
            alt={description}
            onError={onErrorImagePreview}
          />
        </ThumbnailImageContainer>
        {title && <ThumbnailText>{title}</ThumbnailText>}
        {text}
      </ThumbnailWrap>
    </>
  ) : null;
};

export default ThumbnailVideo;
