import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";

import { ROLES, TICKET_CANVAS_TYPES } from "constants/types";
import { ROUTE } from "constants/routing";
import { MEET_STATUS } from "constants/status";

import { hasRoleSelector, scheduleSelector } from "store/selectors/user";
import { getDateRange } from "helpers/time";

import InfiniteScrollContainer from "Components/InfiniteScrollContainer";
import Ticket from "Components/Ticket";
import Loader from "Components/Loader";
import GroupSwitch from "Components/GroupSwitch";
import SearchInput from "Components/SearchInput";
import TicketCanvas from "Components/TicketCanvas";

import { config as meetsConfig } from "./constants";

const { HEAD_COACH, COACH, TEAM_ADMIN, SWIM_COACH } = ROLES;

const today = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 15px;
`;
const StyledTitle = styled.p`
  font-size: 24px;
  font-weight: 600;
`;
const StyledCreateMeetBtn = styled.button`
  padding: 7.5px 15px;
  margin-top: -3px;
  color: ${({ theme }) => theme.basicTextColor};
  font-size: 14px;
  border: none;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.activeBgColor};
  cursor: pointer;
`;
const SubTitleWrap = styled.div`
  padding: 0 20px;

  .switchHolder {
    margin-bottom: 15px;
  }
`;
const StyledBottonNote = styled.p`
  color: #d3d6e2;
  text-align: center;
  font-size: 20px;
  margin: 80px 0;
  display: block;
`;
const StyledNoResults = styled.div`
  text-align: center;
  padding-top: 30px;
  font-size: 24px;
  opacity: 0.5;
`;
const TicketFooter = styled.div`
  position: relative;
  width: 100%;
  padding: 100px 20px 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: ${({ theme }) => theme.basicTextColor};
  box-sizing: border-box;
  cursor: pointer;

  a {
    color: ${({ theme }) => theme.basicTextColor};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  span {
    margin: 0 7px;
  }
`;

const ScheduleSection = ({
  meetsType,
  setMeetsType,
  getNextPage,
  listContainerRef,
  searchValue,
  setSearchValue,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const hasAbilityToCreateMeet = useSelector(
    hasRoleSelector([HEAD_COACH, COACH, TEAM_ADMIN, SWIM_COACH]),
  );
  const { meets, hasMore } = useSelector(scheduleSelector) || {};

  const onCreateMeetClick = () => {
    history.push(ROUTE.CREATE_MEET);
  };

  const onChangeMeetType = (type) => {
    setSearchValue("");
    setMeetsType(type);
  };

  const renderTicketFooter = ({
    id,
    isCoachInMeet,
    status,
    registered,
    // paid,
    // isOncePaid,
    // isSheetPaid,
    // isSheetExists,
    // isSpectatorExists,
  }) => {
    const isMeetClosed = status === MEET_STATUS.CLOSED;
    const isMeetCancelled = status === MEET_STATUS.CANCELLED;

    if (isMeetClosed || isMeetCancelled) return null;

    const links = [];
    // TODO: hidden for prod
    // const isMeetPaid = paid;
    // const isAllSheetsPaid = isSheetExists ? isOncePaid && isSheetPaid : true;

    // if (isMeetPaid && (!isAllSheetsPaid || isSpectatorExists))
    //   links.push({
    //     to: ROUTE.MEET_REGISTRATION(id),
    //     text: t("Pay now"),
    //   });

    if (isCoachInMeet)
      links.push({
        to: ROUTE.MEET_REGISTRATION(id),
        text: t(
          typeof registered === "boolean"
            ? registered
              ? "Registered"
              : "Registration declined"
            : "Coach registration",
        ),
      });

    if (!links.length) return null;

    return (
      <TicketCanvas
        type={TICKET_CANVAS_TYPES.FOOTER}
        gradientTransform="rotate(-90)"
        firstPointStopColor="rgba(0, 0, 0, 0.6)"
        secondPointStopColor="rgba(256, 256, 256, 0.1)"
        style={{ position: "absolute", bottom: 0, zIndex: 0, width: "100%" }}
        withGradientAnimation
      >
        <TicketFooter>
          {links.map(({ to, text }, i, arr) => (
            <React.Fragment key={text}>
              <Link to={to}>{text}</Link>
              {i < arr.length - 1 && <span>/</span>}
            </React.Fragment>
          ))}
        </TicketFooter>
      </TicketCanvas>
    );
  };

  return (
    <>
      <StyledTitleWrapper>
        <StyledTitle data-test="title">{t("Eligible meets")}</StyledTitle>
        {hasAbilityToCreateMeet && (
          <StyledCreateMeetBtn onClick={onCreateMeetClick}>
            {t("Create a meet")}
          </StyledCreateMeetBtn>
        )}
      </StyledTitleWrapper>
      <SubTitleWrap>
        <GroupSwitch
          config={meetsConfig}
          itemType={meetsType}
          setItemType={onChangeMeetType}
        />
        <SearchInput
          width="100%"
          placeholder={t("Search by meet name, host team, city")}
          style={{ marginBottom: 15 }}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </SubTitleWrap>
      <InfiniteScrollContainer
        wrapperRef={listContainerRef}
        dataLength={meets?.length || 0}
        next={getNextPage}
        hasMore={hasMore}
        loader={<Loader />}
        endMessage={
          <StyledBottonNote>
            <BsThreeDots /> {t("That's all for now")} <BsThreeDots />
          </StyledBottonNote>
        }
        noResults={<StyledNoResults>{t("No results")}</StyledNoResults>}
      >
        {meets?.map((props) => (
          <Ticket
            {...props}
            key={props.id}
            date={getDateRange(props.startDate, props.endDate)}
            numberOfDivers={props.meetDiversCount}
            isEnded={props.ended}
            isOpen={props.open}
            governingAbbreviation={props?.governingBody}
            renderTicketFooter={renderTicketFooter}
            style={{ paddingBottom: renderTicketFooter(props) ? 45 : 0 }}
          />
        ))}
      </InfiniteScrollContainer>
    </>
  );
};

export default ScheduleSection;
