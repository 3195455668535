import { JUDGE_TYPES } from "constants/types";
import {
  actionFulfilled,
  GET_MEET_INFO,
  GET_MEET_FINALS_INFO,
  GET_REFRESH_TOKEN,
  DELETE_REFEREE_FROM_LIST,
  SET_REFEREE,
  UPDATE_JUDGES_INFO,
  UPDATE_REFEREES,
  GET_EVENT_JUDGES_TOKENS,
  DELETE_JUDGE_FROM_TOKENS,
  SET_JUDGE_TO_TOKENS,
  UPDATE_EVENT_JUDGES_TOKENS,
  GET_MEET_ADMIN_EVENTS,
  CANCEL_EVENT,
  CLEAR_MEET_ADMIN_EVENTS,
  CREATE_SPLIT_EVENT,
  UPDATE_SPLIT_EVENT,
  UNSPLIT_EVENT,
  LOCK_EVENT,
  LOCK_SESSION,
  LOCK_DAY,
  DIVE_UP_DAY,
  DIVE_DOWN_DAY,
  DIVE_UP_SESSION,
  DIVE_DOWN_SESSION,
  DIVE_UP_EVENT,
  DIVE_DOWN_EVENT,
  REORDER_COMBINED_EVENTS,
  REMOVE_EVENT,
} from "store/actionTypes";

import { groupSplittedEvents } from "helpers/event";

const defaultState = {
  general: {},
  finalsInfo: [],
  judgesTokens: {},
  eventsList: [],
};

const meetAdminReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_MEET_INFO):
      return { ...state, general: payload };
    case actionFulfilled(GET_MEET_FINALS_INFO):
      return { ...state, finalsInfo: payload };
    case actionFulfilled(GET_REFRESH_TOKEN):
      const { eventId, judgeNumber } = payload;

      return {
        ...state,
        judgesTokens: {
          ...state.judgesTokens,
          [eventId]: state.judgesTokens[eventId].map((token) => {
            if (token.eventId === eventId && token.judgeNumber === judgeNumber)
              return payload;

            return token;
          }),
        },
      };
    case DELETE_REFEREE_FROM_LIST: {
      const { eventId, userId } = payload;

      const getJudgePanel = ({ id, judgePanel }) => ({
        ...judgePanel,
        referee:
          id === eventId && judgePanel.referee.id === userId
            ? { id: null }
            : judgePanel.referee,
        assistant:
          id === eventId && judgePanel.assistant.id === userId
            ? { id: null }
            : judgePanel.assistant,
      });

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          judgePanel: getJudgePanel(event),
        })),
      };
    }
    case SET_REFEREE: {
      const { eventId, user, judgeType } = payload;

      const getJudgePanel = ({ id, judgePanel }) => ({
        ...judgePanel,
        referee:
          id === eventId &&
          !judgePanel.referee?.id &&
          judgeType === JUDGE_TYPES.REFEREE
            ? user
            : judgePanel.referee || {},
        assistant:
          id === eventId &&
          !judgePanel.assistant?.id &&
          judgeType === JUDGE_TYPES.ASSISTANT
            ? user
            : judgePanel.assistant || {},
      });

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          judgePanel: getJudgePanel(event),
        })),
      };
    }
    case UPDATE_JUDGES_INFO: {
      return {
        ...state,
        eventsList: state.eventsList.map((event) => {
          const newEventData = payload.find(({ id }) => event?.id === id);

          if (!!newEventData)
            return { ...event, judgePanel: newEventData.judgePanel };

          return event;
        }),
      };
    }
    case UPDATE_REFEREES: {
      const { eventId, referees } = payload;
      const [referee, assistant] = referees;

      const getJudgePanel = ({ id, judgePanel }) => ({
        ...judgePanel,
        referee: id === eventId ? referee : judgePanel.referee,
        assistant: id === eventId ? assistant : judgePanel.assistant,
      });

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          judgePanel: getJudgePanel(event),
        })),
      };
    }
    case UPDATE_EVENT_JUDGES_TOKENS:
    case actionFulfilled(GET_EVENT_JUDGES_TOKENS): {
      return {
        ...state,
        judgesTokens: {
          ...state.judgesTokens,
          ...payload,
        },
      };
    }
    case DELETE_JUDGE_FROM_TOKENS: {
      return {
        ...state,
        judgesTokens: Object.keys(state.judgesTokens).reduce(
          (acc, eventId) => ({
            ...acc,
            [eventId]: state.judgesTokens[eventId].map((token) => ({
              ...token,
              active: token.socketToken === payload ? false : token.active,
              user: token.socketToken === payload ? null : token.user,
              refreshToken: token.socketToken === payload || token.refreshToken,
            })),
          }),
          {},
        ),
      };
    }
    case SET_JUDGE_TO_TOKENS: {
      const { clickedUser, eventsIds } = payload;

      const existedJudgesTokens = state.judgesTokens || {};
      let isJudgeAssigned = false;

      return {
        ...state,
        judgesTokens: Object.keys(existedJudgesTokens).reduce(
          (acc, eventId) => {
            if (!eventsIds.includes(+eventId))
              return { ...acc, [eventId]: existedJudgesTokens[eventId] };

            return {
              ...acc,
              [eventId]: existedJudgesTokens[eventId].map((token) => {
                const { active, user } = token;

                if (!active && !user && !isJudgeAssigned) {
                  isJudgeAssigned = true;

                  return {
                    ...token,
                    user: clickedUser,
                    refreshToken: true,
                  };
                }

                return token;
              }),
            };
          },
          {},
        ),
      };
    }
    case actionFulfilled(GET_MEET_ADMIN_EVENTS):
    case actionFulfilled(CREATE_SPLIT_EVENT):
    case actionFulfilled(UNSPLIT_EVENT):
    case actionFulfilled(REORDER_COMBINED_EVENTS): {
      return { ...state, eventsList: payload };
    }
    case actionFulfilled(UPDATE_SPLIT_EVENT): {
      const splittedEventsList = groupSplittedEvents(state.eventsList);
      const splittedUpdatedEvents = groupSplittedEvents(payload);
      const updatedSplittedEventsList = splittedEventsList.map((event) => {
        const updatedEvent = splittedUpdatedEvents.find(
          ({ id }) => event.id === id,
        );

        return updatedEvent || event;
      });
      const updatedEventsList = updatedSplittedEventsList.flatMap(
        ({ splittedEvents, ...event }) => [event, ...splittedEvents],
      );

      return { ...state, eventsList: updatedEventsList };
    }
    case CLEAR_MEET_ADMIN_EVENTS:
      return { ...state, eventsList: defaultState.eventsList };
    case actionFulfilled(CANCEL_EVENT): {
      const { event: changedEvent } = payload || {};

      return {
        ...state,
        eventsList: state.eventsList.map((event) => {
          const { status, subEvents = [] } = event;
          const newStatus = [event, ...subEvents].some(
            ({ id }) => id === changedEvent?.eventId,
          )
            ? changedEvent?.status
            : null;

          return {
            ...event,
            status: newStatus || status,
            subEvents: subEvents.map((subEvent) => ({
              ...subEvent,
              status: newStatus || subEvent.status,
            })),
          };
        }),
      };
    }
    case actionFulfilled(REMOVE_EVENT): {
      const removedEvent = state.eventsList.find(
        (event) => event.id === payload,
      );

      return {
        ...state,
        eventsList: state.eventsList.filter(
          (event) =>
            !(event.id === removedEvent.id || removedEvent.splittedEventId
              ? event.splittedEventId === removedEvent.splittedEventId
              : false),
        ),
      };
    }
    case actionFulfilled(LOCK_EVENT): {
      const { eventId, locked } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          locked: event.id === eventId ? locked : event.locked,
        })),
      };
    }
    case actionFulfilled(LOCK_SESSION): {
      const { sessionId, locked } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          locked: event.sessionId === sessionId ? locked : event.locked,
        })),
      };
    }
    case actionFulfilled(LOCK_DAY): {
      const { dayId, locked } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          locked: event.dayId === dayId ? locked : event.locked,
        })),
      };
    }
    case actionFulfilled(DIVE_UP_DAY): {
      const { dayId, diveUpEnabled } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          diveUp: event.dayId === dayId ? diveUpEnabled : event.diveUp,
        })),
      };
    }
    case actionFulfilled(DIVE_DOWN_DAY): {
      const { dayId, diveDownEnabled } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          diveDown: event.dayId === dayId ? diveDownEnabled : event.diveDown,
        })),
      };
    }
    case actionFulfilled(DIVE_UP_SESSION): {
      const { sessionId, diveUpEnabled } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          diveUp: event.sessionId === sessionId ? diveUpEnabled : event.diveUp,
        })),
      };
    }
    case actionFulfilled(DIVE_DOWN_SESSION): {
      const { sessionId, diveDownEnabled } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          diveDown:
            event.sessionId === sessionId ? diveDownEnabled : event.diveDown,
        })),
      };
    }
    case actionFulfilled(DIVE_UP_EVENT): {
      const { eventId, diveUpEnabled } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          diveUp: event.id === eventId ? diveUpEnabled : event.diveUp,
        })),
      };
    }
    case actionFulfilled(DIVE_DOWN_EVENT): {
      const { eventId, diveDownEnabled } = payload;

      return {
        ...state,
        eventsList: state.eventsList.map((event) => ({
          ...event,
          diveDown: event.id === eventId ? diveDownEnabled : event.diveDown,
        })),
      };
    }
    default:
      return state;
  }
};

export default meetAdminReducer;
