import { roundsListResponseHandler } from "helpers/rounds";
import { getEventInfo } from "helpers/websocketAdmin";

import axiosInstance, { HTTP_ATT } from "./index";

export const getEventRoundsListRequest = (eventid) =>
  axiosInstance.get(`/event/${eventid}/round/list`).then((response) => {
    const roundsObj = response.data
      .sort((a, b) => {
        if (a.number > b.number) return 1;
        if (a.number < b.number) return -1;

        if (a.sequenceStart > b.sequenceStart) return 1;
        if (a.sequenceStart < b.sequenceStart) return -1;

        return 0;
      })
      .reduce((acc, cur) => {
        if (acc[cur.number]) {
          const sequenceStart = [
            acc[cur.number],
            ...acc[cur.number].subRounds,
          ].reduce((acc, cur) => acc + (cur.dives || []).length, 0);

          return {
            ...acc,
            [cur.number]: {
              ...acc[cur.number],
              subRounds: [
                ...acc[cur.number].subRounds,
                { ...cur, sequenceStart },
              ],
            },
          };
        }

        return {
          ...acc,
          [cur.number]: { ...cur, sequenceStart: 0, subRounds: [] },
        };
      }, {});

    return getEventInfo({
      rounds: Object.values(roundsObj),
    });
  });

export const getEventLiveStatus = () =>
  axiosInstance.get(`/event/live`).then((response) => response?.data);

export const roundDndRequest = (eventId, roundNumber, source, dest) =>
  axiosInstance
    .post(`/event/${eventId}/round/reorder`, { source, dest, roundNumber })
    .then((response) => roundsListResponseHandler(response.data));

export const shuffleDiversRequest = ({ eventId, saveProps }) =>
  axiosInstance.post(`/event/${eventId}/round/shuffle`).then((response) => {
    if (saveProps === "NOT_TO_SAVE") return {};

    return roundsListResponseHandler(response.data);
  });

export const submitDifficultyRequest = (diveId, diveData) =>
  axiosInstance
    .post(`/dive/${diveId}/difficulty/update`, diveData)
    .then((response) => roundsListResponseHandler(response.data));

export const setDiveDifficultyRequest = (diveId, data) =>
  axiosInstance
    .post(`/dive/${diveId}/difficulty/set`, data)
    .then((response) => roundsListResponseHandler(response.data));

export const unscratchDiverRequest = ({ diveId, ...data }) =>
  axiosInstance.post(`/dive/${diveId}/unscratch`, data);

export const scratchDiverRequest = ({ diveId, ...data }) =>
  axiosInstance.post(`/dive/${diveId}/scratch`, data);

export const exhibitionSheetRequest = ({ sheetId, value }) =>
  axiosInstance.post(`/sheet/${sheetId}/${value}/exhibition`);

export const prequalifiedSheetRequest = ({ sheetId, value }) =>
  axiosInstance.post(`/sheet/${sheetId}/${value}/prequalified`);

export const setLastScoresRequest = ({ diveId, ...data }) =>
  axiosInstance
    .post(`/dive/${diveId}/score/last`, data)
    .then((response) => response.data);

export const setOriginalScoresRequest = ({ diveId, ...data }) =>
  axiosInstance
    .post(`/dive/${diveId}/score/original`, data)
    .then((response) => response.data);

export const submitMarksRequest = (diveId, data) =>
  axiosInstance
    .post(`/dive/${diveId}/award`, data)
    .then((response) => response.data);

export const submitPenaltyMarksRequest = (diveId, data) =>
  axiosInstance
    .post(`/dive/${diveId}/award/penalty`, data)
    .then((response) => response.data);

export const closeEventRequest = (eventId) =>
  axiosInstance
    .post(`/event/${eventId}/close`)
    .then((response) => response?.data);

export const getDiveSheetRequest = (diveId) =>
  axiosInstance.get(`/dive/${diveId}/sheet`).then((response) => response?.data);

export const skipDiveRequest = ({ diveId, ...data }) =>
  axiosInstance.post(`/dive/${diveId}/skip`, data);

export const setCheckInStatus = ({ eventId, ticketId, orderId, out }) =>
  HTTP_ATT.post("/dive/manual/checkin", {
    event_id: eventId,
    ticket_id: ticketId,
    order_id: orderId,
    out,
  }).then((response) => response.data);
