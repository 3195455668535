import {
  actionFulfilled,
  GET_MEET_PERMISSIONS_BY_TEAM,
  CLEAR_MEET_PERMISSIONS,
  GET_MEET_SCHEDULE,
  CLEAR_MEET_SCHEDULE,
  GET_MEET_EVENTS_LIST,
  CLEAR_MEET_EVENTS_LIST,
  GET_MEET_JUDGES,
  GET_MEET_TICKETS_INFO,
  GET_MEET_DETAILS,
} from "store/actionTypes";

const initState = {
  meetPermissions: {},
  meetSchedule: {},
  meetEventsList: [],
  meetDetails: {},
  meetJudges: [],
  meetTicketsInfo: [],
};

const meetReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_MEET_PERMISSIONS_BY_TEAM):
      return {
        ...state,
        meetPermissions: payload,
      };
    case CLEAR_MEET_PERMISSIONS:
      return {
        ...state,
        meetPermissions: initState.meetPermissions,
      };

    case actionFulfilled(GET_MEET_SCHEDULE):
      return {
        ...state,
        meetSchedule: payload,
      };
    case CLEAR_MEET_SCHEDULE:
      return {
        ...state,
        meetSchedule: initState.meetSchedule,
      };

    case actionFulfilled(GET_MEET_EVENTS_LIST):
      return {
        ...state,
        meetEventsList: payload,
      };
    case CLEAR_MEET_EVENTS_LIST:
      return {
        ...state,
        meetEventsList: initState.meetEventsList,
      };

    case actionFulfilled(GET_MEET_JUDGES):
      return {
        ...state,
        meetJudges: payload,
      };

    case actionFulfilled(GET_MEET_TICKETS_INFO):
      return {
        ...state,
        meetTicketsInfo: payload,
      };

    case actionFulfilled(GET_MEET_DETAILS):
      return {
        ...state,
        meetDetails: payload,
      };

    default:
      return state;
  }
};

export default meetReducer;
