import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BiErrorAlt } from "react-icons/bi";

import { GCP_URL } from "constants/env";
import { REQUEST_STATUS } from "constants/status";
import { TOP_PADDING } from "constants/settings";

import { getVideo, restoreVideo } from "store/actions/video";
import { selectVideo, selectVideoError } from "store/selectors/video";
import { userDetailsSelector } from "store/selectors/user";
import { getYesNoModalDefaultState } from "constants/types";
import { removeVideoFromUser } from "store/actions/feed";

import MetaTags from "Components/MetaTags";
import DiversTopAvatars from "Components/DiversTopAvatars";
import ConfirmationModal from "Components/ConfirmationModal";
import ReportVideoModal from "Components/ReportVideoModal";
import Button from "Components/Button";

const StyledMain = styled.div`
  display: flex;
  height: 99.5vh;
  flex-direction: column;
  margin-top: -${TOP_PADDING};
`;

const StyledContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const VideoDescription = styled.div`
  padding: 20px;
  line-height: 1.3;
  font-size: 16px;

  b {
    font-weight: bold;
  }

  .description {
    margin: 25px 0 !important;
    color: ${({ theme }) => theme.accentTextColor};
    line-height: 1.3;
    font-size: 16px;
  }
`;

const VideoTag = styled.video`
  width: 100%;
  height: auto;
  max-height: 390px;
`;

const ErrorHolder = styled.div`
  padding: 100px 20px 0;
  text-align: center;
  font-size: 24px;
`;

const ErrorStatusCode = styled.p`
  font-weight: bold;
  font-size: 80px;
`;

const ButtonsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ isAdmin }) => (isAdmin ? 2 : 1)}, 1fr);
  gap: 20px;
  margin-top: 25px;

  button {
    width: auto;
    height: 50px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 10px;
    border: 2px solid;
    cursor: pointer;
    transition: all 0.5s;

    :hover {
      background: transparent;
      box-shadow: none;
    }
  }

  .restore {
    border-color: ${({ theme }) => theme.activeBgColor};
    background: ${({ theme }) => theme.activeBgColor};

    :hover {
      color: ${({ theme }) => theme.activeBgColor};
    }
  }

  .delete {
    border-color: ${({ theme }) => theme.errorColor};
    background: ${({ theme }) => theme.errorColor};

    :hover {
      color: ${({ theme }) => theme.errorColor};
    }
  }
`;

const Video = () => {
  const { t } = useTranslation();
  const { key } = useParams();
  const dispatch = useDispatch();

  const video = useSelector(selectVideo);
  const videoError = useSelector(selectVideoError);
  const userDetails = useSelector(userDetailsSelector);

  const [modalComponent, setModalComponent] = useState(null);

  const {
    id,
    diver,
    secondDiver,
    synchroTeamName,
    url,
    thumbnailUrl,
    totalAward,
    diveHeight,
    diveCode,
    meetName,
    isReported,
    owner,
  } = video || {};
  const { status: errorStatus, message: errorMessage } = videoError || {};
  const divers = [diver, secondDiver].filter((user) => !!user);
  const description = video
    ? `${divers
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(
          " and ",
        )} scored ${totalAward} points on ${diveHeight}M ${diveCode} at ${meetName}`
    : "";
  const isVideoCanBeDeleted =
    divers.some(({ id }) => id === userDetails?.id) || userDetails?.isAdmin;

  const onRestore = () => {
    dispatch(restoreVideo(id));
  };

  const onDelete = (e) => {
    e.preventDefault();

    setModalComponent(
      <ConfirmationModal
        {...{
          ...getYesNoModalDefaultState(t),
          message: t("Are you sure you want to delete this video?"),
          onLeftBtnClick: (e) => {
            e.stopPropagation();
            dispatch(removeVideoFromUser(id));
          },
          onBackdropClick: () => setModalComponent(null),
        }}
      />,
    );
  };

  const dropdownItems = userDetails?.id
    ? [
        {
          id: 1,
          route: "#",
          text: t("Share video"),
          callback: async (e) => {
            e.preventDefault();

            const { onClickShareVideo } = await import("helpers/video");

            onClickShareVideo({ id, text: description, dispatch });
          },
        },
        {
          id: 2,
          route: "#",
          text: t("Report video"),
          callback: (e) => {
            e.preventDefault();

            setModalComponent(
              <ReportVideoModal
                id={id}
                onClose={() => setModalComponent(null)}
              />,
            );
          },
        },
        {
          id: 3,
          route: "#",
          text: t("Delete video"),
          callback: onDelete,
          hidden: !isVideoCanBeDeleted,
        },
      ]
    : null;

  useEffect(() => {
    if (!key) return;

    dispatch(getVideo(key));
  }, [key]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      {modalComponent}
      {errorStatus ? (
        <ErrorHolder>
          <ErrorStatusCode>{errorStatus}</ErrorStatusCode>
          {errorStatus === REQUEST_STATUS.NOT_FOUND && (
            <p>{t("VIDEO NOT FOUND")}</p>
          )}
        </ErrorHolder>
      ) : null}
      {video ? (
        <StyledMain>
          <MetaTags
            description={description}
            imageUrl={`${GCP_URL}${thumbnailUrl}`}
          />

          <StyledContainer>
            {isReported && !isVideoCanBeDeleted ? (
              <ErrorHolder style={{ marginBottom: 50 }}>
                <BiErrorAlt size={150} />
                <p>{t("The video has been reported as inappropriate")}</p>
              </ErrorHolder>
            ) : (
              <VideoTag poster={`${GCP_URL}${thumbnailUrl}`} controls>
                <source src={`${GCP_URL}${url}`} type="video/mp4" />
                {t("Your browser does not support the video tag.")}
              </VideoTag>
            )}

            <VideoDescription>
              {isReported && !isVideoCanBeDeleted ? null : (
                <DiversTopAvatars
                  teamName={synchroTeamName}
                  users={divers}
                  isSmall
                  description={description}
                  dropdownItems={dropdownItems}
                />
              )}

              {isReported && isVideoCanBeDeleted && (
                <>
                  <p style={{ marginBottom: 5 }}>
                    {t("The video has been reported as inappropriate")}
                  </p>
                  <p>
                    <b>{t("Uploaded by")}:</b>{" "}
                    {[owner.firstName, owner.lastName].join(" ")}{" "}
                    <a href={`mailto:${owner.email}`}>{owner.email}</a>
                  </p>
                  <ButtonsWrap isAdmin={userDetails?.isAdmin}>
                    {userDetails?.isAdmin && (
                      <Button
                        styleType="iamDone"
                        className="restore"
                        onClick={onRestore}
                      >
                        {t("Restore")}
                      </Button>
                    )}
                    <Button
                      styleType="delete"
                      className="delete"
                      onClick={onDelete}
                    >
                      {t("Delete")}
                    </Button>
                  </ButtonsWrap>
                </>
              )}
            </VideoDescription>
          </StyledContainer>
        </StyledMain>
      ) : null}
    </>
  );
};

export default Video;
