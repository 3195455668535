import {
  actionFulfilled,
  GET_MEET_SHEET,
  GET_MEET_USER_SHEET,
  CLEAR_MEET_SHEET,
  SET_REORDERED_DIVES,
  SET_DIVE_CODE,
  TOGGLE_OPTIONAL,
  SET_PLATFORM_HEIGHT,
  SET_EMPTY_CODE,
  UPDATE_CUSTOM_DIFF_ON_MEET_REVIEW,
  UPDATE_COMBINED_USER_TEAM_NAME,
  SET_EDITED_SHEET_ITEM,
  SAVE_CODE_FOR_DIVER,
  UPDATE_EDITED_DIVE_NAME,
  SET_EDITED_DIVE,
  SET_EDITED_SHEET,
  SET_MEET_REVIEW_DIVER,
  UPDATE_COACH_FOR_SHEET,
  UPDATE_DEFUALT_COACH_FOR_SHEET,
  UNCOMBINE_SYNCHRO_PAIR,
  REMOVE_DIVE_SHEET,
  RESET_SHEETS,
  GET_MEET_USER_AGE,
  UPDATE_VALUES_FOR_SHEET,
} from "store/actionTypes";
import { DEFAULT_PLATFORM_HEIGHT } from "constants/settings";

const initState = {
  shortMeet: {
    group: {
      name: "",
    },
    venue: {
      city: "",
      country: "",
      firstLine: "",
    },
    days: [],
  },
  sheets: [],
  additionalSheets: [],
  initialSheets: [],
  initialAdditionalSheets: [],
  editedItem: {
    height: DEFAULT_PLATFORM_HEIGHT,
    optional: true,
  },
  editedSheet: {},
  diveDifficultyList: "",
  meetReviewDiver: "",
  isOncePaid: false,
  diverEventInfo: null,
};

const meetReviewReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case actionFulfilled(GET_MEET_SHEET):
      return {
        ...state,
        ...payload,
      };
    case actionFulfilled(GET_MEET_USER_SHEET):
      return {
        ...state,
        ...payload,
      };
    case UPDATE_CUSTOM_DIFF_ON_MEET_REVIEW: {
      const { eventId, sheetId, dives } = payload;

      const mapCallback = (sheet) => {
        const isCurrentSheet =
          (sheetId && sheet.sheetId === sheetId) || eventId === sheet.eventId;

        return isCurrentSheet
          ? {
              ...sheet,
              dives: sheet.dives.map((dive) => {
                const { customDifficulty } =
                  dives.find(({ number }) => number === dive.number) || {};

                return {
                  ...dive,
                  customDifficulty,
                  diveDifficulty: {
                    ...dive.diveDifficulty,
                    customDifficulty,
                  },
                };
              }),
            }
          : { ...sheet };
      };

      return {
        ...state,
        additionalSheets: state.additionalSheets.map(mapCallback),
        sheets: state.sheets.map(mapCallback),
      };
    }
    case CLEAR_MEET_SHEET:
      return initState;
    case SET_REORDERED_DIVES:
      const { sheet: payloadSheet } = payload;

      const mapCallback = (sheet) => {
        if (sheet.eventId !== payloadSheet.eventId) return sheet;

        return payloadSheet;
      };

      return {
        ...state,
        additionalSheets: state.additionalSheets.map(mapCallback),
        sheets: state.sheets.map(mapCallback),
      };
    case SET_EDITED_SHEET_ITEM: {
      const { additionalSheets = [], sheets = [] } = state;
      const allSheets = [...additionalSheets, ...sheets];
      const editedSheet = allSheets.find(
        ({ eventId, mainEventId, combinedUser }) =>
          (mainEventId === payload.eventId || eventId === payload.eventId) &&
          (payload.combinedUserId
            ? combinedUser?.id === +payload.combinedUserId
            : true),
      );
      const editedItem =
        editedSheet?.dives.find(
          ({ number }) => number === payload.diveNumber,
        ) || {};

      return {
        ...state,
        editedSheet,
        editedItem,
      };
    }
    case SAVE_CODE_FOR_DIVER: {
      const mapCallback = (sheet) => {
        const { combinedUser, eventId, dives, mainEventId } = sheet;

        return (
          payload.combinedUserId
            ? combinedUser?.id === +payload.combinedUserId
            : eventId === +payload.eventId || mainEventId === +payload.eventId
        )
          ? {
              ...sheet,
              dives: dives.map((dive) =>
                dive.number === state.editedItem.number &&
                state.editedItem.diveDifficulty?.dive?.name
                  ? state.editedItem
                  : dive,
              ),
            }
          : { ...sheet };
      };
      const additionalSheets = state.additionalSheets.map(mapCallback);
      const sheets = state.sheets.map(mapCallback);
      const editedSheet = [...additionalSheets, ...sheets].find(
        ({ eventId }) => +eventId === +payload.eventId,
      );

      return {
        ...state,
        additionalSheets,
        sheets,
        editedSheet,
      };
    }
    case UPDATE_COACH_FOR_SHEET: {
      const mapCallback = (sheet) =>
        sheet.eventId === +payload.eventId ||
        sheet.mainEventId === +payload.eventId
          ? {
              ...sheet,
              coach: payload.coach,
            }
          : { ...sheet };
      const additionalSheets = state.additionalSheets.map(mapCallback);
      const sheets = state.sheets.map(mapCallback);

      return {
        ...state,
        additionalSheets,
        sheets,
        editedSheet: {
          ...state.editedSheet,
          coach: payload.coach,
        },
      };
    }
    case UPDATE_DEFUALT_COACH_FOR_SHEET: {
      const mapCallback = (sheet) =>
        sheet.eventId === +payload.eventId
          ? {
              ...sheet,
              coach: payload.coach,
            }
          : { ...sheet };

      return {
        ...state,
        initialSheets: state.initialSheets.map(mapCallback),
        initialAdditionalSheets: state.initialAdditionalSheets.map(mapCallback),
      };
    }
    case UPDATE_VALUES_FOR_SHEET: {
      const { eventId, data } = payload;

      const mapCallback = (sheet) =>
        sheet.eventId === +eventId
          ? {
              ...sheet,
              ...data,
            }
          : { ...sheet };

      return {
        ...state,
        additionalSheets: state.additionalSheets.map(mapCallback),
        sheets: state.sheets.map(mapCallback),
      };
    }
    case SET_DIVE_CODE:
      return {
        ...state,
        editedItem: {
          ...state.editedItem,
          code: payload,
        },
      };
    case UPDATE_EDITED_DIVE_NAME: {
      return {
        ...state,
        editedItem: {
          ...state.editedItem,
          ...payload,
        },
      };
    }
    case TOGGLE_OPTIONAL:
      return {
        ...state,
        editedItem: {
          ...state.editedItem,
          optional: !state.editedItem.optional,
        },
      };
    case SET_PLATFORM_HEIGHT:
      return {
        ...state,
        editedItem: {
          ...state.editedItem,
          height: payload,
        },
      };
    case SET_EMPTY_CODE:
      return {
        ...state,
        editedItem: {
          height: DEFAULT_PLATFORM_HEIGHT,
          optional: true,
        },
      };
    case UPDATE_COMBINED_USER_TEAM_NAME: {
      const { id, name } = payload;

      const mapCallback = (sheet) => {
        const newCombinedUser = sheet.combinedUser
          ? {
              ...sheet.combinedUser,
              teamName:
                sheet.combinedUser.id === id
                  ? name
                  : sheet.combinedUser.teamName,
            }
          : sheet.combinedUser;

        return {
          ...sheet,
          combinedUser: newCombinedUser,
        };
      };

      return {
        ...state,
        additionalSheets: state.additionalSheets.map(mapCallback),
        sheets: state.sheets.map(mapCallback),
      };
    }
    case SET_EDITED_DIVE: {
      return {
        ...state,
        editedItem: payload,
      };
    }
    case SET_EDITED_SHEET: {
      return {
        ...state,
        editedSheet: payload,
        sheets: payload ? [payload] : [],
      };
    }
    case SET_MEET_REVIEW_DIVER: {
      return {
        ...state,
        meetReviewDiver: payload,
      };
    }
    case actionFulfilled(UNCOMBINE_SYNCHRO_PAIR): {
      const { id } = payload;

      const filterCallback = ({ combinedUser }) => combinedUser?.id !== id;

      return {
        ...state,
        sheets: state.sheets.filter(filterCallback),
        additionalSheets: state.additionalSheets.filter(filterCallback),
        initialSheets: state.initialSheets.filter(filterCallback),
        initialAdditionalSheets:
          state.initialAdditionalSheets.filter(filterCallback),
      };
    }
    case actionFulfilled(REMOVE_DIVE_SHEET): {
      const filterCallback = ({ diveUser }) => diveUser?.id !== payload;

      return {
        ...state,
        sheets: state.sheets.filter(filterCallback),
        additionalSheets: state.additionalSheets.filter(filterCallback),
        initialSheets: state.initialSheets.filter(filterCallback),
        initialAdditionalSheets:
          state.initialAdditionalSheets.filter(filterCallback),
      };
    }
    case RESET_SHEETS:
      return {
        ...state,
        additionalSheets: state.initialAdditionalSheets,
        sheets: state.initialSheets,
      };
    case actionFulfilled(GET_MEET_USER_AGE): {
      return { ...state, diverEventInfo: payload };
    }
    default:
      return state;
  }
};

export default meetReviewReducer;
