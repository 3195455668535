import { MEET_STEPS } from "./types";

export const ROUTE = {
  LOGIN: "/",
  LOGIN_DETAILS: "/DetailedLogin",
  MEETS_EVENTS: (meetId) => `/Meets/${meetId}/Events`,
  MEETS_REGISTER: (meetId) => `/Meets/${meetId}/Register`,
  MEETS_REGISTER_ADD: (meetId) => `/Meets/${meetId}/AddDiver`,
  MEETS_REGISTER_ON_EVENT: (meetId, eventId) =>
    `/Meets/${meetId}/Register/${eventId}`,
  MEETS_REGISTER_ENTER_SHEET: (meetId, eventId, diverId = "_") =>
    `/Meets/${meetId}/Register/${eventId}/EnterSheet/${diverId}`,
  MEETS_MESSAGES: (meetId) => `/Meets/${meetId}/Messages`,
  MEETS_FINALS: (meetId) => `/Meets/${meetId}/Finals`,
  MEET_EVENT_ROUNDS: (meetId, eventId) =>
    `/Meets/${meetId}/Events/${eventId}/Rounds`,
  MEET_EVENT_GRID: (meetId, eventId) =>
    `/Meets/${meetId}/Events/${eventId}/Grid`,
  MEET_EVENT_ANNOUNCER_VIEW: (meetId, eventId) =>
    `/Meets/${meetId}/Events/${eventId}/AnnouncerView`,
  MEET_EVENT_JUMBOTRON_VIEW: (meetId, eventId) =>
    `/Meets/${meetId}/Events/${eventId}/JumbotronView`,
  MEET_EVENT_LEADERBOARD_VIEW: (meetId, eventId) =>
    `/Meets/${meetId}/Events/${eventId}/LeaderboardView`,
  MEETS_JUDGES: (meetId, routeTab = "assignEvent", eventId = "_") =>
    `/Meets/${meetId}/Judges/${routeTab}/${eventId}`,
  JUDGE_MODE: (meetId, eventId) =>
    `/JudgeMode/${meetId}/CurrentEvent/${eventId}`,
  SWITCH_JUDGE_POSITION: (meetId, eventId) =>
    `/JudgeMode/${meetId}/CurrentEvent/${eventId}/JudgesList`,
  SWITCH_DIVER_POSITION: (meetId, eventId) =>
    `/JudgeMode/${meetId}/CurrentEvent/${eventId}/DiversList`,
  ENTER_DIVE_NUMBER: (meetId, eventId, diveId) =>
    `/JudgeMode/${meetId}/CurrentEvent/${eventId}/DiveList/Dive/${diveId}`,
  PROFILE: (userId = "_") => `/Profile/${userId}`,
  PROFILE_EDIT: "/Profile/_/Edit",
  PROFILE_SETTINGS: "/Profile/_/Settings",
  PROFILE_SUBSCRIPTIONS: "/Profile/_/Subscriptions",
  PROFILE_MEMBERSHIP: "/Profile/_/Membership",
  PROFILE_USERS: (userId = "_") => `/Profile/${userId}/Users`,
  EVENTS: "/Events",
  ACHIEVEMENTS: "/Achievements",
  CHAT: "/Chat",
  DEFAULT: "/Home",
  REGISTRATION: "/Registration",
  FORGOT_PASSWORD: "/ForgotPassword",
  RESET_PASSWORD: (tokenId) => `/ResetPassword/${tokenId}`,
  HOME: "/Home",
  SEARCH: "/Search",
  SEARCH_TEAM: "/Search/Team",
  SEARCH_DIVER: "/Search/Diver",
  SEARCH_MEET: "/Search/Meet",
  SCHEDULE: "/Schedule",
  GROUP: (teamId = "_") => `/Group/${teamId}`,
  INVITE_TEAM_MEMBER: (teamId = "_") => `/Group/${teamId}/Invite`,
  MERGE_ACCOUNTS: (teamId = "_") => `/Group/${teamId}/Merge`,
  JOIN_TEAM_REQUEST: (teamId = "_") => `/Group/${teamId}/Join`,
  TEAM_FOLLOWERS: (teamId = "_") => `/Group/${teamId}/Followers`,
  CREATE_MEET: `/CreateMeet`,
  EDIT_MEET: (meetId = "", step = MEET_STEPS.INVITE_TEAMS) =>
    `/EditMeet/${meetId}/${step}`,
  HELP_REQUEST: "/HelpRequest",
  LEADERSHIP: (eventId, screenName, diverId = "_") =>
    `/Leadership/${eventId}/${screenName}/${diverId}`,
  MEET_SHORT: (meetId) => `/MeetShort/${meetId}`,
  MEET_REGISTRATION: (meetId) => `/MeetShort/${meetId}/Registration`,
  DIVE_SHEETS: (meetId, eventId) =>
    `/MeetShort/${meetId}/Registration/_/${eventId}/DiveList`,
  DIVE_SHEETS_SYNCHRO: (meetId, eventId) =>
    `/MeetShort/${meetId}/Registration/_/${eventId}/DiveList/Synchro`,
  SYNCHRO_DIVE_SHEET: (meetId, eventId, combinedUserId) =>
    `/MeetShort/${meetId}/Registration/_/${eventId}]/DiveList/Synchro/${combinedUserId}`,
  DIVE_CODE: (meetId, eventId, diveNumber) =>
    `/MeetShort/${meetId}/Registration/_/${eventId}/DiveList/Dive/${diveNumber}`,
  SYNCHRO_DIVE_CODE: (meetId, eventId, combinedUserId, diveNumber) =>
    `/MeetShort/${meetId}/Registration/_/${eventId}/DiveList/Synchro/${combinedUserId}/Dive/${diveNumber}`,
  CHANGE_PASSWORD: `/ChangePassword`,
  VIDEO: (key) => `/Video/${key}`,
  DIVER_DIVE_SHEETS: (meetId, eventId) =>
    `/MeetShort/${meetId}/Registration/Diver/${eventId}/DiveList`,
  DIVER_DIVE_SHEETS_SYNCHRO: (meetId, eventId) =>
    `/MeetShort/${meetId}/Registration/Diver/${eventId}/DiveList/Synchro`,
  SYNCHRO_DIVER_DIVE_SHEET: (meetId, eventId, combinedUserId) =>
    `/MeetShort/${meetId}/Registration/Diver/${eventId}/DiveList/Synchro/${combinedUserId}`,
  DIVER_DIVE_CODE: (meetId, eventId, diveNumber) =>
    `/MeetShort/${meetId}/Registration/Diver/${eventId}/DiveList/Dive/${diveNumber}`,
  SYNCHRO_DIVER_DIVE_CODE: (meetId, eventId, combinedUserId, diveNumber) =>
    `/MeetShort/${meetId}/Registration/Diver/${eventId}/DiveList/Synchro/${combinedUserId}/Dive/${diveNumber}`,
};
