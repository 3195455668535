import axiosInstance from "./index";

export const userLogInRequest = (data) =>
  new Promise((res, rej) => {
    axiosInstance
      .post(`auth/login`, data)
      .then((response) => {
        if (process.env.REACT_APP_ENV === "PROD") {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("event", "login", {
            method: "native",
          });
        }

        const {
          userId,
          status: subscription,
          dob,
          admin: isAdmin,
          firstName,
          lastName,
          jwtToken: token,
        } = response.data;

        res({
          userId,
          subscription,
          username: data.username,
          token,
          dob: dob === "null" ? "" : dob,
          isAdmin,
          firstName,
          lastName,
        });
      })
      .catch((error) => {
        rej(error);
      });
  });

export const userRegistrationRequest = (data) =>
  axiosInstance
    .post("user/registration", data)
    .then(() => ({ username: data.email }));

export const userGoogleLogInRequest = (id_token) =>
  axiosInstance.get(`/auth/google?id_token=${id_token}`);

export const judgeLogoutRequest = (token, noAppVerification = false) =>
  axiosInstance.get(`/judgemode/token/${token}/exit`, { noAppVerification });

export const getCurrentUserInfoRequest = () =>
  axiosInstance.get("/user/my/info").then((response) => response.data);

export const getCurrentJudgeInfoRequest = (eventId) =>
  axiosInstance
    .get(`/judgemode/token/event/${eventId}`)
    .then((response) => response.data);

export const getCurrentEventInformationForJudgeRequest = (token) =>
  axiosInstance
    .get(`/judgemode/token/${token}/event/info`)
    .then((response) => response.data);

export const judgeSignInByCodeRequest = ({ token, ...data }) =>
  axiosInstance
    .post(`/judgemode/token/${token}`, data, { withoutCredentials: true })
    .then((response) => response.data);

export const judgeSignInByQRCodeRequest = ({
  code,
  noAppVerification = false,
  ...data
}) =>
  axiosInstance
    .post(`/judgemode/token/qr/${code}`, data, {
      withoutCredentials: true,
      noAppVerification,
    })
    .then((response) => response.data);

export const getCustomUserInfoRequest = (userId) =>
  axiosInstance
    .get(`/user/${userId}/profile`)
    .then((response) => response.data);

export const saveCurrentUserInfoRequest = (data) =>
  axiosInstance
    .post("/user/my/info/edit", data)
    .then((response) => response.data);

export const removeCurrentUserPortraitRequest = () =>
  axiosInstance.post("/user/my/portrait/remove");

export const removeUserBgPhotoRequest = () =>
  axiosInstance.post("/user/my/portrait/background/remove");

export const getNextFeedPageRequest = (pageNumber = 1, params) =>
  axiosInstance
    .get(`/feed/${pageNumber}`, params)
    .then((response) => ({ ...response.data, pageNumber }));

export const sendNewLinkToEmailRequest = (data) =>
  axiosInstance
    .post(`/password/change`, data)
    .then((response) => response.data);

export const sendNewPasswordRequest = (data) =>
  axiosInstance.post(`/password/new`, data).then((response) => response.data);

export const passwordChangeRequest = (data) =>
  axiosInstance
    .post(`/user/my/password/change`, data)
    .then((response) => response.data);

export const getCurrentSubscriptionPlanRequest = () =>
  axiosInstance.get(`/user/my/status`).then((response) => response.data);

export const helpRequest = (data) =>
  axiosInstance.post(`/email/help`, data).then((response) => response.data);

export const updateDiveNumberRequest = ({ diveId, token, data }) =>
  axiosInstance
    .post(`dive/${diveId}/difficulty/update/token/${token}`, data)
    .then((response) => response.data);

export const getAttendizeAuthToken = (userId) =>
  axiosInstance.get(`user/${userId}/token`).then((response) => response.data);

export const checkAAUMembership = (data) =>
  axiosInstance
    .post("membership/aau/verify", data)
    .then((response) => response.data);

export const getAAUMembership = () =>
  axiosInstance.get("membership/aau/my").then((response) => response.data);

export const deleteProfileRequest = () =>
  axiosInstance
    .post("user/delete/current", {})
    .then((response) => response.data);

export const mergeUsersRequest = (data) =>
  axiosInstance.post("user/join/users", data).then((response) => response.data);

export const searchUsersRequest = (key) =>
  axiosInstance
    .get(`user/join/users/info/${key}`)
    .then((response) => response.data);
