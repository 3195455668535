export const FINALS_STAGE_TYPE = {
  FINALS: "FINALS",
  SEMI_FINALS: "SEMI_FINALS",
  PRELIMS: "PRELIMS",
  CONSOLS: "CONSOLS",
};

export const FINALS_STAGE_TYPE_VALUES = {
  FINALS: 2,
  SEMI_FINALS: 1,
  PRELIMS: 0,
};

export const FINALS_STAGE_TYPE_SHORT_NAME = {
  FINALS: "FINALS",
  SEMI_FINALS: "SEMI",
  PRELIMS: "PRELIMS",
  CONSOLS: "CONSOLS",
};

export const FORMAT_CODES = {
  NFHS_SEMI_FINALS_AND_FINALS: "NFHS_SEMI_FINALS_AND_FINALS",
  FINALS: "FINALS",
  CONSOLS_FINALS: "CONSOLS_AND_FINALS",
};
