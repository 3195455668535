import React from "react";
import styled from "styled-components";

const SwitchWrapper = styled.div`
  & > * {
    border: 1px solid #cdd7ec;
    ${({ customCss }) => customCss}
  }
`;

const StyledSwitchHolder = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  min-height: 35px;
  border-radius: 17.5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
`;

const StyledPlaceHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  text-align: center;
  border-radius: 17.5px;

  &.checked {
    background-color: ${({ theme }) => theme.basicBlue};
    color: ${({ theme }) => theme.basicTextColor};
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
    cursor: default;
  }
`;

const GroupSwitch = ({ config, itemType, setItemType, containerCss }) => (
  <SwitchWrapper customCss={containerCss}>
    <StyledSwitchHolder className="switchHolder">
      {config
        .filter(({ hidden = false }) => !hidden)
        .map(({ key, children, disabled = false }, _, array) => {
          const className = [
            itemType === key ? "checked" : "",
            disabled ? "disabled" : "",
          ]
            .filter((name) => name)
            .join(" ");

          return (
            <StyledPlaceHolder
              key={key}
              className={className}
              onClick={() => setItemType(key)}
              style={{ flexBasis: `${100 / array.length}%` }}
            >
              {children}
            </StyledPlaceHolder>
          );
        })}
    </StyledSwitchHolder>
  </SwitchWrapper>
);

export default GroupSwitch;
