import { toast } from "react-toastify";

import {
  ADD_JUDGE,
  GET_ALL_JUDGES,
  START_EVENT,
  GET_AVAILABLE_GOVERNING_BODYES,
  CANCEL_EVENT,
  UPDATE_JUDGE_STATUS,
  REMOVE_EVENT,
  GET_SEARCH_MEETS,
  GET_MEET_SHEET,
  GET_MEET_USER_SHEET,
  CLEAR_MEET_SHEET,
  SET_REORDERED_DIVES,
  NEW_MEET_DND_EVENT_COMBINE,
  NEW_MEET_EVENT_UNCOMBINE,
  NEW_MEET_EVENT_REORDER,
  SET_DIVE_CODE,
  DELETE_MEET,
  TOGGLE_OPTIONAL,
  SET_PLATFORM_HEIGHT,
  SET_EMPTY_CODE,
  CUT_EXTRA_INVITED_JUDGES,
  SET_EVENT_ID_FOR_EDITING,
  GET_MEET_ADMINS,
  GET_CUSTOM_MEET_JUDGES,
  GET_USERS_BY_SEARCH_KEY,
  SEARCH_USERS,
  SEARCH_VENUES,
  ADD_SEARCHED_VENUE_TO_LIST,
  CLEAR_SEARCH_VENUES,
  GET_MEW_MEET_JUDGES_BY_SEARCH_KEY,
  ADD_USERS_TO_MEET_USERS,
  UPDATE_CUSTOM_DIFF_ON_MEET_REVIEW,
  GET_REFRESH_TOKEN,
  HANDLE_SPLIT_MODAL,
  HANDLE_CANCEL_MODAL,
  HANDLE_MOVE_DIVER_MODAL,
  SPLIT_BOARD_PARAMS,
  MOVE_DIVER,
  SWITCH_JUDGE_POSITION,
  SWITCH_DIVER_POSITION,
  GET_ALL_COLORS_SPLIT_BOARDS,
  UPLOAD_ATTACHMENT,
  GET_JUDGES_BY_PAGE,
  GET_NEW_MEET_JUDGES_BY_PAGE,
  UPDATE_COMBINED_USER_TEAM_NAME,
  SET_EDITED_SHEET_ITEM,
  SAVE_CODE_FOR_DIVER,
  UPDATE_EDITED_DIVE_NAME,
  SET_EDITED_DIVE,
  SET_EDITED_SHEET,
  GET_SEARCH_MEETS_PER_PAGE,
  REMOVE_DIVER_FROM_EVENT,
  GET_MEET_USER_AGE,
  GET_MEET_PERMISSIONS,
  GET_SEARCH_PAST_MEETS,
  GET_SEARCH_CURRENT_MEETS,
  GET_SEARCH_FUTURE_MEETS,
  GET_CREATED_JUDGES,
} from "store/actionTypes";
import { GCP_BUCKET_UPLOAD_URL } from "constants/env";
import {
  DEFAULT_PLATFORM_HEIGHT,
  MIXED_EVENT_HEIGHT,
  getMinMixedHeights,
} from "constants/settings";

import {
  startEventRequest,
  getGoverningBodyRequest,
  combineEventsRequest,
  uncombineEventsRequest,
  cancelEventRequest,
  removeDiverFromEventRequest,
  removeEventRequest,
  getSearchMeetsRequest,
  getMeetSheetRequest,
  getMeetUserSheetRequest,
  reorderCombinedEventsRequest,
  getMeetAdminsRequest,
  getUsersByKeyRequest,
  searchUsersRequest,
  searchVenuesRequest,
  moveDiverRequest,
  getAllColorsSplitBoardsRequest,
  setAttachmentRequest,
  getMeetUserAgeRequest,
  getMeetPermissionsRequest,
  getSearchPastMeetsRequest,
  getSearchСurrentMeetsRequest,
  getSearchFutureMeetsRequest,
} from "API/meets";
import {
  addJudgesInMeet,
  getAllJudgesRequest,
  getJudgesByMeet,
  getRefreshToken,
  switchJudgePositionRequest,
  switchDiverPositionRequest,
  getJudgesByPageRequest,
  getCreatedJudgesRequest,
} from "API/judges";
import { uploadFileToGCP } from "API/files";
import { getMeetDetails } from "store/actions/meet";

const updateSheets = ({ sheets, governingBody }) =>
  sheets.map((event) => {
    const { dives, eventHeight, coach, combinedUser, diveUser } = event;
    const isEventMixedByHeight = eventHeight === MIXED_EVENT_HEIGHT;
    const isPlatform = eventHeight === DEFAULT_PLATFORM_HEIGHT;

    return {
      ...event,
      dives: dives.map((dive) => {
        const defaultHeight = isEventMixedByHeight
          ? getMinMixedHeights(governingBody)
          : isPlatform
          ? DEFAULT_PLATFORM_HEIGHT
          : dive.height;

        return {
          ...dive,
          height: dive.id ? dive.height : defaultHeight,
        };
      }),
      coach: combinedUser?.users
        ? combinedUser.users.map(({ id, coach }) => ({
            coachId: coach?.id,
            diverId: id,
          }))
        : diveUser
        ? [
            {
              coachId: coach?.id,
              diverId: diveUser.id,
            },
          ]
        : [],
    };
  });

export const refreshJudgeToken = (token) => ({
  type: GET_REFRESH_TOKEN,
  payload: getRefreshToken(token),
});

export const addUsersToMeetUsers = (users) => ({
  type: ADD_USERS_TO_MEET_USERS,
  payload: users,
});

export const getCustomMeetJudges = (meetId) => ({
  type: GET_CUSTOM_MEET_JUDGES,
  payload: getJudgesByMeet(meetId),
});

export const createJudge = (
  meetId,
  { firstName, lastName, email, teamId },
) => ({
  type: ADD_JUDGE,
  payload: addJudgesInMeet(meetId, {
    userInfo: { firstName, lastName, email },
    teamId,
  }),
});

export const switchJudgePosition = ({
  id,
  token,
  data,
  successfulCallback,
}) => ({
  type: SWITCH_JUDGE_POSITION,
  payload: switchJudgePositionRequest({ id, token, data }).then((res) => {
    successfulCallback();

    return res;
  }),
});

export const switchDiverPosition = ({
  id,
  token,
  data,
  successfulCallback,
}) => ({
  type: SWITCH_DIVER_POSITION,
  payload: switchDiverPositionRequest({ id, token, data }).then((res) => {
    successfulCallback();

    return res;
  }),
});

export const getAllJudgesList = () => {
  return {
    type: GET_ALL_JUDGES,
    payload: getAllJudgesRequest(),
  };
};

export const getJudgesByPage = (meetId, page, size) => ({
  type: GET_JUDGES_BY_PAGE,
  payload: getJudgesByPageRequest(meetId, page, size),
});

export const getCreatedJudges = (meetId) => ({
  type: GET_CREATED_JUDGES,
  payload: getCreatedJudgesRequest(meetId),
});

export const getUsersBySearchKey = (key = "") => ({
  type: GET_USERS_BY_SEARCH_KEY,
  payload: getUsersByKeyRequest(key),
});

export const searchUsers = (data) => ({
  type: SEARCH_USERS,
  payload: searchUsersRequest(data),
});

export const searchVenues = (data) => ({
  type: SEARCH_VENUES,
  payload: searchVenuesRequest(data),
});

export const addSearchedVenueToList = (payload) => ({
  type: ADD_SEARCHED_VENUE_TO_LIST,
  payload,
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH_VENUES,
});

export const getNewMeetJudgesBySearchKey = (key = "") => ({
  type: GET_MEW_MEET_JUDGES_BY_SEARCH_KEY,
  payload: getUsersByKeyRequest(key),
});

export const startEvent = (data) => ({
  type: START_EVENT,
  payload: startEventRequest(data),
});

export const getAvailableGoverningBodyes = (teamId) => {
  return {
    type: GET_AVAILABLE_GOVERNING_BODYES,
    payload: getGoverningBodyRequest(teamId),
  };
};

export const handleSplitModal = (payload) => ({
  type: HANDLE_SPLIT_MODAL,
  payload,
});

export const handleCancelEventModal = (payload) => {
  return {
    type: HANDLE_CANCEL_MODAL,
    payload,
  };
};

export const handleMoveDiverModal = (payload) => {
  return {
    type: HANDLE_MOVE_DIVER_MODAL,
    payload,
  };
};

export const uncombineEventsFromNewMeet = (eventId) => {
  return {
    type: NEW_MEET_EVENT_UNCOMBINE,
    payload: uncombineEventsRequest(eventId),
  };
};
export const reorderCombinedEventsFromNewMeet = (eventId) => {
  return {
    type: NEW_MEET_EVENT_REORDER,
    payload: reorderCombinedEventsRequest(eventId),
  };
};

export const combineEventsOnCreateMeetSreen = (eventIds) => {
  return {
    type: NEW_MEET_DND_EVENT_COMBINE,
    payload: combineEventsRequest(eventIds),
  };
};

export const cancelEvent = (eventId) => ({
  type: CANCEL_EVENT,
  payload: cancelEventRequest(eventId),
});

export const removeDiverFromEvent = ({
  eventId,
  diverId,
  successCallback = () => {},
}) => {
  return {
    type: REMOVE_DIVER_FROM_EVENT,
    payload: removeDiverFromEventRequest(eventId, diverId).then((data) => {
      successCallback(data);

      return diverId;
    }),
  };
};

export const getNewMeetJudgesByPageRequest = (meetId, page = 0, size = 10) => ({
  type: GET_NEW_MEET_JUDGES_BY_PAGE,
  payload: getJudgesByPageRequest(meetId, page, size),
});

export const setJudgeStatus = (payload) => {
  return {
    type: UPDATE_JUDGE_STATUS,
    payload,
  };
};

export const cutExtraInvitedJudgesTo = (maxPossible) => {
  return {
    type: CUT_EXTRA_INVITED_JUDGES,
    payload: maxPossible,
  };
};

export const removeEvent = (eventId) => ({
  type: REMOVE_EVENT,
  payload: removeEventRequest(eventId),
});

export const getMeetPermissions = (meetId) => ({
  type: GET_MEET_PERMISSIONS,
  payload: getMeetPermissionsRequest(meetId),
});

export const getSearchMeets = (type, page = 0) => {
  return {
    type: GET_SEARCH_MEETS,
    payload: getSearchMeetsRequest(type, page).then((response) => ({
      [type]: response?.meets || [],
    })),
  };
};

export const getSearchMeetsPerPage = (type, page) => {
  return {
    type: GET_SEARCH_MEETS_PER_PAGE,
    payload: getSearchMeetsRequest(type, page).then((response) => ({
      type,
      meets: response?.meets || [],
    })),
  };
};

export const getSearchPastMeets = (keyword, page) => ({
  type: GET_SEARCH_PAST_MEETS,
  payload: getSearchPastMeetsRequest(keyword, page).then((response) => ({
    ...response,
    page,
  })),
});

export const getSearchСurrentMeets = (keyword, page) => ({
  type: GET_SEARCH_CURRENT_MEETS,
  payload: getSearchСurrentMeetsRequest(keyword, page).then((response) => ({
    ...response,
    page,
  })),
});

export const getSearchFutureMeets = (keyword, page) => ({
  type: GET_SEARCH_FUTURE_MEETS,
  payload: getSearchFutureMeetsRequest(keyword, page).then((response) => ({
    ...response,
    page,
  })),
});

export const getMeetSheet = (meetId, teamId) => ({
  type: GET_MEET_SHEET,
  payload: getMeetSheetRequest(meetId, teamId).then(
    ({
      additionalEvents,
      events,
      governingBody,
      isOncePaid,
      startDate,
      endDate,
    }) => {
      const sheets = updateSheets({
        sheets: events,
        governingBody,
      });
      const additionalSheets = updateSheets({
        sheets: additionalEvents,
        governingBody,
      });

      return {
        sheets,
        initialSheets: sheets,
        additionalSheets,
        initialAdditionalSheets: additionalSheets,
        diveDifficultyList: governingBody,
        isOncePaid,
        startDate,
        endDate,
      };
    },
  ),
});

export const getMeetUserAge = (params) => ({
  type: GET_MEET_USER_AGE,
  payload: getMeetUserAgeRequest(params),
});

export const getMeetUserSheet = (params) => ({
  type: GET_MEET_USER_SHEET,
  payload: getMeetUserSheetRequest(params).then(
    ({
      additionalEvents,
      events,
      governingBody,
      isOncePaid,
      startDate,
      endDate,
    }) => {
      const sheets = updateSheets({
        sheets: events,
        governingBody,
      });
      const additionalSheets = updateSheets({
        sheets: additionalEvents,
        governingBody,
      });

      return {
        sheets,
        initialSheets: sheets,
        additionalSheets,
        initialAdditionalSheets: additionalSheets,
        diveDifficultyList: governingBody,
        isOncePaid,
        startDate,
        endDate,
      };
    },
  ),
});

export const updateCustomDifficultiesOnMeetReview = (payload) => ({
  type: UPDATE_CUSTOM_DIFF_ON_MEET_REVIEW,
  payload,
});

export const clearMeetSheet = () => ({
  type: CLEAR_MEET_SHEET,
});

export const reorderDives = (payload) => {
  return {
    type: SET_REORDERED_DIVES,
    payload,
  };
};

export const setDiveCodeAction = (payload) => {
  return {
    type: SET_DIVE_CODE,
    payload,
  };
};

export const deleteMeetAction = (meetId) => {
  return {
    type: DELETE_MEET,
    payload: meetId,
  };
};

export const toggleOptionalAction = () => ({
  type: TOGGLE_OPTIONAL,
});

export const setHeightAction = (height) => {
  return {
    type: SET_PLATFORM_HEIGHT,
    payload: height,
  };
};

export const setEmptyCodeAction = () => {
  return {
    type: SET_EMPTY_CODE,
  };
};

export const setEventIdForEditing = (eventId) => {
  return {
    type: SET_EVENT_ID_FOR_EDITING,
    payload: eventId,
  };
};

export const getMeetAdmins = (meetId) => ({
  type: GET_MEET_ADMINS,
  payload: getMeetAdminsRequest(meetId),
});

export const setSplitBoardParams = (payload) => {
  return {
    type: SPLIT_BOARD_PARAMS,
    payload,
  };
};

export const getAllColorsSplitBoards = (meetId) => ({
  type: GET_ALL_COLORS_SPLIT_BOARDS,
  payload: getAllColorsSplitBoardsRequest(meetId),
});

export const moveDiver = ({ successCallback = () => {}, ...data }) => ({
  type: MOVE_DIVER,
  payload: moveDiverRequest(data).then(() => {
    successCallback();

    return data;
  }),
});

export const uploadAttachment =
  ({ file, token, fileName, meetId, type }) =>
  (dispatch) => {
    const url = GCP_BUCKET_UPLOAD_URL();

    return {
      type: UPLOAD_ATTACHMENT,
      payload: uploadFileToGCP({
        data: file,
        token,
        name: fileName,
        url,
      })
        .then(() => {
          const data = {
            attachments: [
              {
                name: fileName,
                type: type.toUpperCase(),
              },
            ],
          };
          setAttachmentRequest(data, meetId).then(() =>
            dispatch(getMeetDetails({ meetId })),
          );
        })
        .then(() =>
          toast.success("The attachment has been uploaded successfully"),
        ),
    };
  };

export const updateCombinedUserTeamName = (payload) => ({
  type: UPDATE_COMBINED_USER_TEAM_NAME,
  payload,
});

export const setEditedSheetItem = (payload) => ({
  type: SET_EDITED_SHEET_ITEM,
  payload,
});

export const saveCodeForDiver = (payload) => ({
  type: SAVE_CODE_FOR_DIVER,
  payload,
});

export const updateEditedDiveName = (payload) => ({
  type: UPDATE_EDITED_DIVE_NAME,
  payload,
});

export const setEditedDive = (payload) => ({
  type: SET_EDITED_DIVE,
  payload,
});

export const setEditedSheet = (payload) => ({
  type: SET_EDITED_SHEET,
  payload,
});
