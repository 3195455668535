import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";

import { getDiveWarnObject } from "helpers/dives";

const ARROW_BOTTOM_POS = 5.6;
const DEFAULT_BODY_STYLE = {
  top: 0,
  left: 0,
  visibility: "hidden",
};

const StyledDiv = styled.div`
  position: relative;
  display: inline-block;
  cursor: default;
`;

const HintBody = styled.div`
  position: fixed;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  max-width: 350px;
  padding: 13px 20px;
  font-weight: initial;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: initial;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.accentBackgroundColor};
  border: 2px solid black;
  border-radius: 6px;

  & svg {
    fill: ${({ theme }) => theme.basicBlue};
    border: 1px solid ${({ theme }) => theme.basicBlue};
    border-radius: 20px;
  }
`;

const HintWarn = ({
  children,
  htmlDescription = null,
  description = "",
  descriptionSeparator,
  style = {},
}) => {
  const hintRef = useRef(null);
  const hintBodyRef = useRef(null);

  const [bodyStyle, setBodyStyle] = useState(DEFAULT_BODY_STYLE);

  useEffect(() => {
    const hint = hintRef.current;
    let isTouchDevice = false;

    const showHint = ({ clientX = 0, clientY = 0, view }) => {
      const hintBody = hintBodyRef.current;
      const hintBodyHeight = hintBody?.offsetHeight || 0;
      const hintBodyWidth = hintBody?.offsetWidth || 0;
      const { innerWidth } = view;

      const top = clientY - hintBodyHeight - ARROW_BOTTOM_POS;
      const left = clientX - hintBodyWidth / 2;

      setBodyStyle({
        top: top < 0 ? clientY + 15 : top,
        left:
          left < 0
            ? 0
            : left + hintBodyWidth > innerWidth
            ? left - (left + hintBodyWidth - innerWidth)
            : left,
        visibility: "visible",
      });
    };

    const onMouseOver = (props) => {
      if (!isTouchDevice) showHint(props);
    };

    const onMouseOut = () => {
      setBodyStyle(DEFAULT_BODY_STYLE);
    };

    const onTouchStart = (e) => {
      isTouchDevice = true;
      showHint({
        clientX: e.touches[0].clientX,
        clientY: e.touches[0].clientY,
        view: e.view,
      });
    };

    const onTouchEnd = () => {
      setBodyStyle(DEFAULT_BODY_STYLE);
    };

    hint?.addEventListener("mouseover", onMouseOver);
    hint?.addEventListener("mouseout", onMouseOut);
    hint?.addEventListener("touchstart", onTouchStart);
    hint?.addEventListener("touchend", onTouchEnd);

    return () => {
      hint?.removeEventListener("mouseover", onMouseOver);
      hint?.removeEventListener("mouseout", onMouseOut);
      hint?.removeEventListener("touchstart", onTouchStart);
      hint?.removeEventListener("touchend", onTouchEnd);
    };
  }, []);

  return !!description || !!htmlDescription ? (
    <StyledDiv ref={hintRef}>
      {children}
      {createPortal(
        <HintBody ref={hintBodyRef} style={{ ...style, ...bodyStyle }}>
          {htmlDescription}
          {getDiveWarnObject(description, descriptionSeparator)?.map(
            ({ error }, index) => (
              <p key={`${error}_${index}`}>{error}</p>
            ),
          )}
        </HintBody>,
        document.body,
      )}
    </StyledDiv>
  ) : (
    children
  );
};

export default HintWarn;
