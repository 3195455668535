import { getJumpSchemaString } from "helpers/dives";

import axiosInstance from "./index";

export const getMeetRequest = (id) =>
  axiosInstance.get(`/meet/${id}`).then((response) => response?.data);

export const getMeetAdminEventsRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/admin/list`)
    .then((response) => response?.data);

export const getMeetInfoRequest = (meetId) =>
  axiosInstance.get(`/meet/${meetId}/info`).then((response) => response?.data);

export const getMeetFinalsInfoRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/finals/info`)
    .then((response) => response?.data);

export const getUsersByKeyRequest = (key) =>
  axiosInstance
    .get(`/user/search/${encodeURIComponent(key)}`)
    .then((response) => response?.data);

export const searchUsersRequest = ({ key, governingBodyId }) =>
  axiosInstance
    .get(`/user/search/${encodeURIComponent(key)}/gb/${governingBodyId}`)
    .then((response) => response?.data);

export const searchVenuesRequest = ({ key }) =>
  axiosInstance
    .get(`/venues/search/${encodeURIComponent(key)}`)
    .then((response) => response?.data);

export const getMeetAssignedTeamsEditMeetRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/teams/edit`)
    .then((response) => response?.data);

export const getMeetTeamsRequest = (meetId) =>
  axiosInstance
    .get(`/meet/${meetId}/teams`)
    .then((response) => ({ meetTeams: response?.data, meetId }));

export const setMeetTeamsRequest = (meetId, data) =>
  axiosInstance
    .post(`/meet/${meetId}/teams`, data)
    .then((response) => response?.data);

export const meetDndRequest = (data) =>
  axiosInstance
    .post("/session/reorder", data)
    .then((response) => response?.data);

export const startEventRequest = (eventId) =>
  axiosInstance
    .post(`/event/${eventId}/start`)
    .then((response) => response?.data);

export const pauseEventRequest = (eventId) =>
  axiosInstance
    .post(`/event/${eventId}/pause`)
    .then((response) => response?.data);

export const resumeEventRequest = (eventId) =>
  axiosInstance
    .post(`/event/${eventId}/resume`)
    .then((response) => response?.data);

export const getGoverningBodyRequest = (teamId) =>
  axiosInstance
    .get(`governingbody/team/${teamId}`)
    .then((response) => response.data);

export const getTeamsRequest = ({ governingBodyId, page = 0 }) =>
  axiosInstance
    .get(`team/governingBody/${governingBodyId}/all/${page}`)
    .then((response) => response.data);

export const getAllTeamsRequest = ({ governingBodyId }) =>
  axiosInstance
    .get(`team/governingBody/${governingBodyId}/all`)
    .then((response) => response.data);

export const searchTeamsRequest = ({ governingBodyId, key = "", page = 0 }) =>
  axiosInstance
    .get(
      `team/governingBody/${governingBodyId}/search/${page}/${encodeURIComponent(
        key,
      )}`,
    )
    .then((response) => response.data);

export const getSearchAvailableTeamsRequest = () =>
  axiosInstance.get(`team/search`).then((response) => response.data);

export const getSearchAvailableTeamsByPageRequest = (page = 0, size = 10) =>
  axiosInstance
    .get(`team/search/all/${page}/${size}`)
    .then((response) => response.data);

export const getFilteredTeamsRequest = (key) =>
  axiosInstance
    .get(`team/search/key/${encodeURIComponent(key)}`)
    .then((response) => response.data);

export const createMeetRequest = (data) =>
  axiosInstance
    .post("meet/create/initial", data)
    .then((response) => response.data);

export const createTeamVenueRequest = (data) =>
  axiosInstance
    .post("venues/create/team", data)
    .then((response) => response.data);

export const getCountryISO = () =>
  axiosInstance
    .get("country/all")
    .then((response) =>
      response.data.sort(({ countryName: a }, { countryName: b }) =>
        a.localeCompare(b),
      ),
    );

export const getStatesByCountryISO = (countryISO) =>
  axiosInstance.get(`state/${countryISO}`).then((response) => response.data);

export const getTeamsVenuesRequest = (data) =>
  axiosInstance
    .post("meet/teams/venues", data)
    .then((response) => response.data);

export const combineEventsRequest = (eventIds) =>
  axiosInstance
    .post("event/combined/combine", eventIds)
    .then((response) => response.data);

export const uncombineEventsRequest = (eventId) =>
  axiosInstance
    .post(`event/combined/${eventId}/uncombine`)
    .then((response) => response.data);

export const reorderCombinedEventsRequest = (eventId) =>
  axiosInstance
    .post(`event/combined/${eventId}/reorder`)
    .then((response) => response.data);

export const cancelEventRequest = (eventId) =>
  axiosInstance.post(`event/${eventId}/cancel`).then((response) => ({
    event: response.data,
  }));

export const removeDiverFromEventRequest = (eventId, diverId) =>
  axiosInstance
    .post(`event/${eventId}/diver/remove`, {
      diverId,
    })
    .then((response) => response.data);

export const setMeetInfoRequest = (data, meetId) =>
  axiosInstance
    .post(`meet/${meetId}/create/info`, data)
    .then((response) => response.data);

export const setMeetDatesRequest = (dates, meetId) =>
  axiosInstance
    .post(`meet/${meetId}/create/dates`, dates)
    .then((response) => response.data);

export const createMeetDayRequest = (data, meetId) =>
  axiosInstance
    .post(`/meet/${meetId}/day/create`, data)
    .then((response) => response.data);

export const changeMeetDayRequest = (data, meetId) =>
  axiosInstance
    .post(`/meet/${meetId}/day/change`, data)
    .then((response) => response.data);

export const deleteMeetDayRequest = (data, meetId) =>
  axiosInstance
    .post(`/meet/${meetId}/day/delete`, data)
    .then((response) => response.data);

export const setMeetFeesRequest = (data, meetId) =>
  axiosInstance
    .post(`meet/${meetId}/create/fees`, data)
    .then((response) => response.data);

export const setMeetPaymentRequest = (data, meetId) =>
  axiosInstance
    .post(`meet/${meetId}/create/payment-method`, data)
    .then((response) => response.data);

export const setMeetSessionsRequest = (sessions, meetId) =>
  axiosInstance
    .post(`meet/${meetId}/create/sessions`, sessions)
    .then((response) => response.data);

export const addEventRequest = (event, sessionId) =>
  axiosInstance
    .post(`session/${sessionId}/event/add`, event)
    .then((response) => response.data);

export const updateMeetRequest = ({ days, ...meet }) => {
  const newMeet = {
    ...meet,
    days: days.map(({ sessions, ...day }) => ({
      ...day,
      sessions: sessions.map(({ events, ...session }) => ({
        ...session,
        events: events.map(
          ({ subEvents = [], splittedEvents = [], ...event }) => ({
            ...event,
            diveSheets: [],
            splittedEvents: splittedEvents.map((splittedEvent) => ({
              ...splittedEvent,
              diveSheets: [],
            })),
            subEvents: subEvents.map(
              ({ splittedEvents = [], ...subEvent }) => ({
                ...subEvent,
                diveSheets: [],
                splittedEvents: splittedEvents.map((splittedEvent) => ({
                  ...splittedEvent,
                  diveSheets: [],
                })),
              }),
            ),
          }),
        ),
      })),
    })),
  };

  return axiosInstance
    .post(`/meet/${meet.id}/update`, newMeet)
    .then((response) => response?.data);
};

export const updateCreatedEventRequest = (updatedEvent) =>
  axiosInstance
    .post(`event/${updatedEvent.id}/update`, updatedEvent)
    .then((response) => response.data);

export const meetSetJudgesRequest = (judges, meetId) =>
  axiosInstance
    .post(`/meet/${meetId}/create/judges`, judges)
    .then((response) => response.data);

export const meetFinishRequest = (meetId) =>
  axiosInstance
    .post(`/meet/${meetId}/create/final`, {})
    .then((response) => response.data);

export const getEligibleMeetsRequest = ({
  isFuture = false,
  page = 0,
  ...data
}) =>
  axiosInstance
    .post(`/meet/schedule/${isFuture}/${page}`, data, { withSpinner: !page })
    .then((response) => response.data);

export const searchEligibleMeetsRequest = ({
  isFuture = false,
  page = 0,
  key = "",
  ...data
}) =>
  axiosInstance
    .post(`/meet/search/${isFuture}/${page}/${key}`, data)
    .then((response) => response.data);

export const askTeamMembershipRequest = (data) =>
  axiosInstance
    .post(`/invitation/team/invite`, data)
    .then((response) => response.data);

export const getFollowersRequest = (teamId) =>
  axiosInstance
    .get(`/team/${teamId}/followers`)
    .then((response) => response.data);

export const getMyTeamMembersRequest = (teamId) =>
  axiosInstance
    .get(`/team/${teamId}/members`)
    .then((response) => response.data);

export const acceptInvitationRequest = (key) =>
  axiosInstance
    .post(`/invitation/${key}/accept`)
    .then((response) => response.data);

export const assignInvitationRequest = (data) =>
  axiosInstance
    .post("invitation/team/assign", data)
    .then((response) => response.data);

export const leaveTeamRequest = (teamId, data) =>
  axiosInstance
    .post(`/team/${teamId}/leave`, data)
    .then((response) => response.data);

export const getUserTeamsRequest = ({ page = 0 }) =>
  axiosInstance
    .get(`/login/teams/${page}`, { withSpinner: !page })
    .then((response) => response.data);

export const getUserTeamRolesRequest = (id) =>
  axiosInstance
    .get(`user/my/team/${id}/roles`)
    .then((response) => response.data);

export const searchUserTeamsRequest = ({ key, page = 0 }) =>
  axiosInstance
    .get(`/login/teams/search/${key}/${page}`)
    .then((response) => response.data);

export const removeTeamMemberRequest = (teamId, memberData) =>
  axiosInstance
    .post(`/team/${teamId}/remove`, memberData)
    .then((response) => response.data);

export const declineInvitationRequest = (key) =>
  axiosInstance
    .post(`/invitation/${key}/decline`)
    .then((response) => response.data);

export const getInvitationsListRequest = (teamId) =>
  axiosInstance
    .get(`/team/${teamId}/invitations`)
    .then((response) => response.data);

export const removeEventRequest = (eventId) =>
  axiosInstance
    .post("/session/event/remove", { eventId })
    .then((response) => response.data);

export const getSearchMeetsRequest = (type, page) =>
  axiosInstance
    .get(`/meet/find/groups/${type}/${page}`)
    .then((response) => response.data);

export const getSearchPastMeetsRequest = (keyword, page = 0) =>
  axiosInstance
    .get(`/meet/find/${encodeURIComponent(keyword)}/groups/past/${page}`)
    .then((response) => response.data);

export const getSearchСurrentMeetsRequest = (keyword, page = 0) =>
  axiosInstance
    .get(`/meet/find/${encodeURIComponent(keyword)}/groups/current/${page}`)
    .then((response) => response.data);

export const getSearchFutureMeetsRequest = (keyword, page = 0) =>
  axiosInstance
    .get(`/meet/find/${encodeURIComponent(keyword)}/groups/future/${page}`)
    .then((response) => response.data);

export const postFollowMeetRequest = (meetId) =>
  axiosInstance
    .post(`/meet/${meetId}/follow`)
    .then((response) => response.data);

export const postUnfollowMeetRequest = (meetId) =>
  axiosInstance
    .post(`/meet/${meetId}/unfollow`)
    .then((response) => response.data);

export const getEventLeadershipRoundRequest = (eventId) =>
  axiosInstance
    .get(`/event/${eventId}/leader/round`)
    .then((response) => response.data);

export const getMeetSheet = (url) =>
  axiosInstance
    .get(url)
    .then((response) => response?.data)
    .then((sheet) => ({
      ...sheet,
      events: sheet.events.map((event) => {
        const jumpSchemaString = getJumpSchemaString(event.jumpSchema);

        return {
          ...event,
          dives: event.dives.map((dive) => ({
            ...dive,
            optional: dive.code
              ? dive.optional
              : jumpSchemaString[dive.number - 1] === "o",
          })),
        };
      }),
      additionalEvents: sheet?.additionalEvents?.map((event) => {
        const jumpSchemaString = getJumpSchemaString(event.jumpSchema);

        return {
          ...event,
          warnings: event.warnings || "",
          dives: event.dives.map((dive) => ({
            ...dive,
            optional: dive.code
              ? dive.optional
              : jumpSchemaString[dive.number - 1] === "o",
          })),
        };
      }),
    }));

export const getMeetSheetRequest = (meetId, teamId) =>
  getMeetSheet(`/meet/${meetId}/sheets/my/${teamId}`);

export const getMeetUserSheetRequest = ({ meetId, userId, teamId }) =>
  getMeetSheet(`/meet/${meetId}/sheets/user/${userId}/team/${teamId}`);

export const getMeetUserAgeRequest = ({ meetId, userId }) =>
  axiosInstance
    .get(`/user/${userId}/age/${meetId}`)
    .then((response) => response.data);

export const assignDivesRequest = (eventId, data) =>
  axiosInstance
    .post(`event/${eventId}/diver/my`, data)
    .then((response) => response?.data);

export const postAssignEventsForDiverRequest = ({ data }) =>
  axiosInstance.post("event/diver/my", data).then((response) => response.data);

export const postAssignEventsForDiverByIdRequest = ({ data, diverId }) =>
  axiosInstance
    .post(`event/diver/${diverId}`, data)
    .then((response) => response.data);

export const postDeleteMeetRequest = (meetId) =>
  axiosInstance.post(`meet/${meetId}/delete`).then((response) => response.data);

export const getAvailableTypesRequest = (data) =>
  axiosInstance
    .post(`event/rules/schema`, data, { withSpinner: false })
    .then((response) => response.data);

export const getAvailableRulesRequest = (schema, data) =>
  axiosInstance
    .post(`event/rules/${schema}/codes`, data, { withSpinner: false })
    .then((response) => response.data);

export const getTeamDetailsRequest = (teamId) =>
  axiosInstance.get(`team/${teamId}/home`).then((response) => response.data);

export const getMeetAdminsRequest = (meetId) =>
  axiosInstance.get(`meet/${meetId}/admins`).then((response) => response.data);

export const getMeetPermissionsRequest = (meetId) =>
  axiosInstance
    .get(`meet/${meetId}/permissions`)
    .then((response) => response.data);

export const getRemoveSessionAtCreateMeet = (sessionId) =>
  axiosInstance
    .post(`session/session/${sessionId}/remove`)
    .then((response) => response.data);

export const moveDiverRequest = (data) =>
  axiosInstance
    .post("events/splitted/move/diver", data)
    .then((response) => response.data);

export const getAllColorsSplitBoardsRequest = (meetId) =>
  axiosInstance.get(`meet/${meetId}/colours`).then((response) => response.data);

export const setAttachmentRequest = (data, meetId) =>
  axiosInstance
    .post(`attachment/upload/${meetId}`, data)
    .then((response) => response.data);

export const deleteAttachmentRequest = (data, meetId) =>
  axiosInstance
    .post(`attachment/delete/${meetId}`, data)
    .then((response) => response.data);

export const getMeetTickets = (meetId) =>
  axiosInstance.get(`meet/${meetId}/tickets`).then((response) => response.data);
