import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { HiOutlineTerminal } from "react-icons/hi";

import { judgeSignInByCode } from "store/actions/user";

import FormikInput from "Components/FormikInputs/FormikInput";
import Button from "Components/Button";

const EnterCodeForm = () => {
  const { t } = useTranslation();
  const formikRef = useRef();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const code = values.code?.toUpperCase().trim();

    dispatch(judgeSignInByCode({ token: code, userId: null }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ code: "" }}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      <Form>
        <FormikInput
          name="code"
          valueNormalizer={(v) => v?.toUpperCase().trim()}
          label={t("Code")}
          icon={<HiOutlineTerminal />}
          required
        />

        <Button
          data-test="signInByCodeSubmitButton"
          type="submit"
          styleType="logIn"
        >
          {t("Sign In")}
        </Button>
      </Form>
    </Formik>
  );
};

export default EnterCodeForm;
