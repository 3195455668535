export const userSelector = (state) => state.user;

export const userDetailsSelector = (state) => state.user.details || {};

export const userProfileSelector = (state) => state.user.profile || {};

export const judgeCodesSelector = (state) => state.user.codes;

export const eventJudgeInformationSelector = (state) =>
  state.user.eventInformation;

export const userTeamsSelector = (state) => state.user.teams || {};

export const availableTeamsSelector = (state) => state?.user?.teams?.list || [];

export const selectedTeamSelector = (state) =>
  state?.user?.teams?.selectedTeam || {};

export const userTeamIdSelector = (state) =>
  state?.user?.teams?.selectedTeam?.id;

export const selectedTeamAutoInviteSelector = (state) =>
  !!state?.user?.teams?.selectedTeam?.autoInviteEnabled;

export const hasRoleSelector =
  (listOfRoles = []) =>
  (state) =>
    state?.user?.teams?.selectedTeam?.roles?.some((role) =>
      listOfRoles.includes(role),
    );

export const dobOfCurrentUserSelector = (state) => state.user.details.dob;

export const subscriptionSelector = (state) => state?.user?.subscription;

export const userIdSelector = (state) => state?.user?.details?.id;

export const scheduleSelector = (state) => state.schedule;

export const userMembershipsSelector = (state) =>
  state?.user?.memberships || [];
