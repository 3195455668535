import React from "react";
import { useSelector } from "react-redux";

import { wsCurrentRoundSelector } from "store/selectors/leadershipScreens";
import { selectJudgeModeMessage } from "store/selectors/websockets";

import RulesButton from "Components/RulesButton";

const JudgeModeRulesButton = () => {
  const { diveOnScreen: wsDiveOnScreen } = useSelector(wsCurrentRoundSelector);
  const { info } = useSelector(selectJudgeModeMessage) || {};

  const defaultRound = info?.rounds?.find((round) => round);

  const { eventId: currentEventId, eventDetails } = wsDiveOnScreen || {};
  const { subEvents = [], ...mainEvent } =
    eventDetails?.eventRulesDescription || {};
  const { id, rulesDescription } =
    [mainEvent, ...subEvents].find(({ id }) => id === currentEventId) ||
    defaultRound ||
    {};

  return (
    <RulesButton
      event={{ id, rulesDescription }}
      style={{
        marginTop: 5,
        marginRight: 15,
      }}
    />
  );
};

export default JudgeModeRulesButton;
