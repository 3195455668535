import { toast } from "react-toastify";

import { ROUTE } from "constants/routing";
import { INDOOR_THEME } from "assets/styles/themes";
import { CLEAR_JUDGE_DETAILS } from "store/actionTypes";
import { AUTH_KEY } from "constants/storageKeys";

import { setColorModeTheme } from "store/actions/settings";
import { logOutUser } from "helpers/user";
import { postJudgemodeFocus } from "API/judges";

export const getNextDive = ({ allDives, currentDiveId, judgeLimit }) => {
  const currentDiveIndex = allDives.findIndex(
    ({ dive }) => dive.id === currentDiveId,
  );
  const futureDives = allDives.slice(currentDiveIndex + 1);
  const nextDive = futureDives.find(
    ({ isScratched, isSkipped, awards }) =>
      !isScratched && !isSkipped && awards.length !== judgeLimit,
  );

  return nextDive;
};

export const goToNextDive = ({
  allDives,
  currentDiveId,
  reScore = false,
  judgeLimit,
  eventId,
}) => {
  const nextDive = getNextDive({ allDives, currentDiveId, judgeLimit });

  if (currentDiveId)
    postJudgemodeFocus({
      nextDiveId: nextDive?.dive?.id,
      currentDiveId,
      reScore,
      eventId,
    });
};

export const logoutJudgeMode = ({ dispatch, history, meetId }) => {
  toast.error("Access denied");
  dispatch(setColorModeTheme(INDOOR_THEME));
  dispatch({ type: CLEAR_JUDGE_DETAILS });

  const isJudgeUnregistered = !localStorage.getItem(AUTH_KEY);

  if (isJudgeUnregistered) {
    logOutUser(dispatch);
  } else {
    history.push({
      pathname: ROUTE.MEET_SHORT(meetId),
      search: "?tab=judges",
    });
  }
};
