import React from "react";

import HintWarn from "Components/HintWarn";

import { BUTTONS_MAP } from "./constants";
import Base from "./Base";

const Button = ({ hintDescription, className, styleType, ...rest }) => {
  const renderButton = !!BUTTONS_MAP[styleType]
    ? BUTTONS_MAP[styleType]
    : (props) => <Base {...props} />;

  return (
    <HintWarn description={hintDescription}>
      {renderButton({ styleType, className: `button ${className}`, ...rest })}
    </HintWarn>
  );
};

export default Button;
