import {
  actionFulfilled,
  CLAER_ROUNDS_LIST,
  DND_ROUND_REPLACE,
  GET_ALL_EVENT_ROUNDS,
  SET_EDIT_DIVE_TO_INITIAL,
  SHUFFLE_DIVERS,
  START_EVENT,
  SUBMIT_DIVE_DIFFICULTY,
  UPDATE_DIVE_IN_LIST,
  SET_DIVE_DIFFICULTY,
  UPDATE_ROUNDS,
  SET_FOCUSED_DIVE,
  UPDATE_DIVER_DIVES,
  UPDATE_DIVER_DIVE,
  SCRATCH_DIVE_SHEET,
  UPDATE_DIVER_DIVE_SHEET,
  MOVE_DIVER,
} from "store/actionTypes";

const formatDives = (dives) =>
  (dives || []).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.dive.id]: {
        ...cur,
        initDive: cur.dive,
      },
    }),
    {},
  );

const setInitDive = (round) => ({
  ...round,
  dives: formatDives(round.dives),
  subRounds: round.subRounds.map((subRound) => ({
    ...subRound,
    dives: formatDives(subRound.dives),
  })),
});

const defaultState = {
  rounds: {},
};

const roundsDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionFulfilled(GET_ALL_EVENT_ROUNDS):
    case actionFulfilled(DND_ROUND_REPLACE):
    case actionFulfilled(SHUFFLE_DIVERS):
    case actionFulfilled(SUBMIT_DIVE_DIFFICULTY):
    case actionFulfilled(SET_DIVE_DIFFICULTY):
    case UPDATE_ROUNDS: {
      const { rounds } = action.payload;

      if (!rounds) return state;

      return {
        ...state,
        rounds: Object.keys(rounds).reduce(
          (acc, cur) => ({ ...acc, [cur]: setInitDive(rounds[cur]) }),
          {},
        ),
      };
    }
    case actionFulfilled(START_EVENT):
      return {
        ...state,
        rounds: {
          ...state.rounds,
          1: {
            ...state.rounds[1],
            status: "IN_PROGRESS",
            timeLimitDTO: { startTime: action.payload.time },
          },
        },
      };
    case SET_EDIT_DIVE_TO_INITIAL: {
      const { roundNumber } = action.payload;

      const updateDives = (dives = {}) =>
        Object.keys(dives).reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: {
              ...dives[cur],
              dive: dives[cur].initDive || dives[cur].dive,
            },
          }),
          {},
        );

      return {
        ...state,
        rounds: {
          ...state.rounds,
          [roundNumber]: {
            ...state.rounds[roundNumber],
            dives: updateDives(state.rounds[roundNumber].dives),
            subRounds: state.rounds[roundNumber].subRounds.map((subRound) => ({
              ...subRound,
              dives: updateDives(subRound.dives),
            })),
          },
        },
      };
    }

    case UPDATE_DIVE_IN_LIST: {
      const { roundNumber, diveData, height, diveId } = action.payload;

      const reduceCallback = (acc, cur) =>
        cur.dive?.id
          ? {
              ...acc,
              [cur.dive.id]:
                cur.dive.id === +diveId
                  ? {
                      ...cur,
                      dive: {
                        ...cur.dive,
                        code: diveData?.code,
                        diveDifficulty: {
                          ...cur.dive.diveDifficulty,
                          divePosition: diveData?.divePosition,
                          code: diveData?.code,
                          difficulty: diveData?.difficulty,
                          customDifficulty: diveData?.customDifficulty || "",
                          dive: {
                            ...cur.dive.diveDifficulty.dive,
                            name: diveData.name,
                          },
                          diveHeight: {
                            ...cur.dive.diveDifficulty?.diveHeight,
                            meters: height,
                          },
                        },
                      },
                    }
                  : { ...cur },
            }
          : acc;

      return {
        ...state,
        rounds: {
          ...state.rounds,
          [roundNumber]: {
            ...state.rounds[roundNumber],
            dives: Object.values(state.rounds[roundNumber].dives).reduce(
              reduceCallback,
              {},
            ),
            subRounds: state.rounds[roundNumber]?.subRounds.map((subRound) => ({
              ...subRound,
              dives: Object.values(subRound.dives).reduce(reduceCallback, {}),
            })),
          },
        },
      };
    }
    case CLAER_ROUNDS_LIST:
      return { ...state, rounds: { ...defaultState.rounds } };
    case SET_FOCUSED_DIVE: {
      const { newFocus: focusedDiveId, roundStatuses } = action.payload;

      const getDives = (dives) =>
        Object.values(dives).reduce(
          (acc, cur) => ({
            ...acc,
            [cur.dive.id]: {
              ...cur,
              current: cur.dive.id === focusedDiveId,
            },
          }),
          {},
        );

      return {
        ...state,
        rounds: Object.keys(state.rounds).reduce((acc, cur) => {
          const round = { ...state.rounds[cur] };

          return {
            ...acc,
            [cur]: {
              ...round,
              status: roundStatuses[round.id],
              dives: getDives(round.dives),
              subRounds: round.subRounds.map((subRound) => ({
                ...subRound,
                status: roundStatuses[subRound.id],
                dives: getDives(subRound.dives),
              })),
            },
          };
        }, {}),
      };
    }
    case UPDATE_DIVER_DIVES: {
      const { dives: newDives, roundStatuses } = action.payload;

      const getDives = (dives) =>
        Object.values(dives).map((dive) => {
          const newDive = newDives.find(
            (newDive) => newDive.dive.id === dive.dive.id,
          );

          if (!newDive) return dive;

          return newDive;
        });

      return {
        ...state,
        rounds: Object.keys(state.rounds).reduce((acc, cur) => {
          const round = { ...state.rounds[cur] };

          return {
            ...acc,
            [cur]: {
              ...round,
              status: roundStatuses[round.id],
              dives: formatDives(getDives(round.dives)),
              subRounds: round.subRounds.map((subRound) => ({
                ...subRound,
                status: roundStatuses[subRound.id],
                dives: formatDives(getDives(subRound.dives)),
              })),
            },
          };
        }, {}),
      };
    }
    case UPDATE_DIVER_DIVE: {
      const { dive: newDive } = action.payload;

      const getDives = (dives) =>
        Object.values(dives).map((dive) => {
          if (dive.dive.id === newDive.dive.id) return newDive;

          return dive;
        });

      return {
        ...state,
        rounds: Object.keys(state.rounds).reduce((acc, cur) => {
          const round = { ...state.rounds[cur] };

          return {
            ...acc,
            [cur]: {
              ...round,
              dives: formatDives(getDives(round.dives)),
              subRounds: round.subRounds.map((subRound) => ({
                ...subRound,
                dives: formatDives(getDives(subRound.dives)),
              })),
            },
          };
        }, {}),
      };
    }
    case actionFulfilled(SCRATCH_DIVE_SHEET): {
      const { sheetId, isScratched } = action.payload;

      const getDives = (dives) =>
        Object.values(dives).map((dive) => {
          if (dive.sheetId === sheetId) {
            dive.isScratched = isScratched;
          }

          return dive;
        });

      return {
        ...state,
        rounds: Object.keys(state.rounds).reduce((acc, cur) => {
          const round = { ...state.rounds[cur] };

          return {
            ...acc,
            [cur]: {
              ...round,
              dives: formatDives(getDives(round.dives)),
              subRounds: round.subRounds.map((subRound) => ({
                ...subRound,
                dives: formatDives(getDives(subRound.dives)),
              })),
            },
          };
        }, {}),
      };
    }
    case actionFulfilled(UPDATE_DIVER_DIVE_SHEET): {
      const {
        coach,
        combinedUserInfo,
        dives: newDives,
        exhibition,
        id,
        prequalified,
        warnings,
      } = action.payload;

      const getDives = (dives) =>
        Object.values(dives).map((dive) => {
          if (dive.sheetId !== id) return dive;

          return {
            ...dive,
            coach,
            combinedUser: combinedUserInfo,
            dive: newDives.find(({ id }) => id === dive.dive.id),
            exhibition,
            prequalified,
            warnings,
          };
        });

      return {
        ...state,
        rounds: Object.keys(state.rounds).reduce((acc, cur) => {
          const round = { ...state.rounds[cur] };

          return {
            ...acc,
            [cur]: {
              ...round,
              dives: formatDives(getDives(round.dives)),
              subRounds: round.subRounds.map((subRound) => ({
                ...subRound,
                dives: formatDives(getDives(subRound.dives)),
              })),
            },
          };
        }, {}),
      };
    }

    case actionFulfilled(MOVE_DIVER): {
      const { diveSheetId } = action.payload;

      const getDives = (dives) =>
        Object.values(dives).filter((dive) => dive.sheetId !== diveSheetId);

      return {
        ...state,
        rounds: Object.keys(state.rounds).reduce((acc, cur) => {
          const round = { ...state.rounds[cur] };

          return {
            ...acc,
            [cur]: {
              ...round,
              dives: formatDives(getDives(round.dives)),
              subRounds: round.subRounds.map((subRound) => ({
                ...subRound,
                dives: formatDives(getDives(subRound.dives)),
              })),
            },
          };
        }, {}),
      };
    }

    default:
      return state;
  }
};

export default roundsDataReducer;
