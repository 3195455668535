import React, { useEffect, lazy, Suspense, memo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTE } from "constants/routing";
import { AUTH_KEY } from "constants/storageKeys";

import { sendMessageToMobile } from "helpers/general";
import { userSelector, judgeCodesSelector } from "store/selectors/user";
import axiosInstance from "API/index";
import { lazyRetry } from "helpers/general";

import ErrorBoundary from "Components/ErrorBoundary";
import ErrorFallback from "Components/ErrorFallback";
import Spinner from "Components/Spinner";
import Login from "Scenes/Login";

import LoaderContainer from "./LoaderContainer";
import CommonRouterLayout from "./CommonRouterLayout";

// Rare routs
const Registration = lazy(() => lazyRetry(() => import("Scenes/Registration")));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("Scenes/ForgotPassword")),
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import("Scenes/ResetPassword")),
);

const LoginRouter = memo(() => {
  const user = useSelector(userSelector);
  const code = useSelector(judgeCodesSelector);

  const renderLoginScene = (component) => {
    if (user && user.token && !code) {
      return <Redirect to={user.redirect || ROUTE.DEFAULT} />;
    }

    return component;
  };

  useEffect(() => {
    axiosInstance.interceptors.request.use((config) => {
      const authorizationToken = config.authKey
        ? localStorage.getItem(config.authKey)
        : localStorage.getItem(AUTH_KEY);

      if (!authorizationToken || config.withoutCredentials) {
        delete config.headers["Authorization"];
      } else {
        config.headers["Authorization"] = `Bearer ${authorizationToken}`;
      }

      return config;
    });

    if (!localStorage.getItem(AUTH_KEY)) return;

    sendMessageToMobile({
      message: `jwtToken_${localStorage.getItem(AUTH_KEY)}`,
    });
  }, [localStorage.getItem(AUTH_KEY)]);

  return (
    <>
      <LoaderContainer />
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route
              path={ROUTE.LOGIN}
              exact
              render={() => renderLoginScene(<Login />)}
            />
            <Route
              path={ROUTE.REGISTRATION}
              exact
              render={() => renderLoginScene(<Registration />)}
            />
            <Route
              path={ROUTE.FORGOT_PASSWORD}
              exact
              render={() => renderLoginScene(<ForgotPassword />)}
            />
            <Route
              path={ROUTE.RESET_PASSWORD(":tokenId")}
              exact
              render={() => renderLoginScene(<ResetPassword />)}
            />
            <Route path="/:page" component={CommonRouterLayout} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </>
  );
});

LoginRouter.displayName = "LoginRouter";

export default LoginRouter;
