import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import defaultTeamImg from "assets/images/mocks/defaultTeamImg.png";
import face from "assets/images/mocks/face.png";
import defaultDeletedUserImg from "assets/images/mocks/face-deleted.png";

import { GCP_BUCKET_URL_AVATARS } from "constants/env";
import { AVATAR_TYPE } from "constants/types";

const StyledImg = styled.img`
  width: ${({ isSmall }) => (isSmall ? "40px" : "60px")};
  height: ${({ isSmall }) => (isSmall ? "40px" : "60px")};
  border-radius: ${({ isSmall }) => (isSmall ? "13px" : "20px")};
  object-fit: cover;
  flex-shrink: 0;
`;

const AvatarItem = ({
  avatar,
  avatarType,
  isSmall = false,
  disableCache = false,
  style = {},
  onClick = () => {},
}) => {
  const [image, setImage] = useState(null);

  const defaultPhoto = (function () {
    switch (avatarType) {
      case AVATAR_TYPE.DELETED:
        return defaultDeletedUserImg;

      case AVATAR_TYPE.TEAM:
        return defaultTeamImg;

      default:
        return face;
    }
  })();

  const onError = () => setImage(defaultPhoto);

  useEffect(() => {
    if (avatar) {
      setImage(
        `${GCP_BUCKET_URL_AVATARS}${avatar}_small${
          disableCache ? `?disableCache=${uuidv4()}` : ""
        }`,
      );

      return;
    }

    onError();
  }, [avatar, avatarType]);

  return (
    <StyledImg
      src={image}
      style={style}
      isSmall={isSmall}
      onError={onError}
      onClick={onClick}
      alt={image}
    />
  );
};

export default AvatarItem;
